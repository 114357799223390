import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EnciApiService } from '../../../../services/enciapi.service';
import { BaseComponent } from '../../components/base/base.component';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { labelTipoSocio } from '../../shared.functions';
import { Observable, combineLatest } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MenuItem } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { ModuloAEnci } from '../../interfaces/enci';
import { ModuloABService } from '../../../../services/moduloab.service';
import { TranslateService } from '../../../../services/translate.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, OnDestroy {
  T$: Observable<any>;
  translateItems: any;

  @Input()
  isInSidebar: boolean = false;

  @Output()
  hideSidebar: EventEmitter<boolean> = new EventEmitter();

  hideCreateModuloA: boolean = false;
  showModuloBPart: boolean = true;

  nomeCognomeAllevatore: string;
  labelTipoSocio: string;
  acronymTipoSocio: string;

  allevatore$: Observable<any>;

  languages$: Observable<any[]>;
  languages: any[];
  languageCurrent: any = {};

  private menuItems: MenuItem[];

  get navigationItems(): MenuItem[] {
    return this.menuItems.filter(i => !this.hideCreateModuloA || (this.hideCreateModuloA && i.id !== 'aggiungi_denuncia'));
  }

  mode = '';
  geneticId = '';
  idPropFattrice = '';
  items: any[];

  activeIndex: number | number[] = -1;
  showAnomalie: boolean = false;

  constructor(
    private router: Router,
    private enciApiService: EnciApiService,    
    private oidcSecurityService: OidcSecurityService,
    private moduloABService: ModuloABService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();


    this.languages$ = this.moduloABService.getLanguages().pipe(
      tap(languages => {
        this.languages = languages;
        if(this.moduloABService.utente) {
          this.languageCurrent = languages.find(language => language.id == this.moduloABService.utente.langId);
        }
      })
    );

    this.T$ = this.translateService.translatedItems$(['menu.','sidebar.','tiposocio.']).pipe(
      tap(items => {
        this.translateItems = items;
        this.initMenu();
      })
    );
    
    this.analyzeUrl();
    this.allevatore$ = this.enciApiService.getAllevatore().pipe(tap((allevatore) => {
      this.nomeCognomeAllevatore = allevatore.anagrafica.nome;
      this.labelTipoSocio =this.translateService.translate(this.translateItems, labelTipoSocio(allevatore));
      this.acronymTipoSocio = allevatore.anagrafica.tiposocio ?? 'N/A';
    }));

    combineLatest([
      this.enciApiService.getCachedModuloA().pipe(startWith(undefined)),
      this.router.events.pipe(startWith(new NavigationEnd(0, this.router.url, this.router.url)))
    ])
      .pipe(takeUntil(this.destroy$)).subscribe({
        next: ([moduloa, ev]) => {

          if (ev instanceof NavigationEnd) {

            let showHidePartB = true;
            let moduloaEnci = moduloa as ModuloAEnci;
            if (moduloaEnci) {

              showHidePartB = moduloaEnci.idstatomodb != '-3' && moduloaEnci.idstatomodb != null;
              this.showAnomalie = moduloaEnci.anomalie?.length > 0;
            }
            this.manageSidebar(ev.url, showHidePartB);
          }
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  initMenu() {
    this.menuItems = [
      {
        id: 'aggiungi_denuncia',
        icon: 'plus',
        label: this.translateService.translate(this.translateItems, 'sidebar.nuovomoda'), // 'Inserisci un nuovo modello A',
        title: this.translateService.translate(this.translateItems, 'menu.nuovadenunca'), // 'Inserisci una nuova denuncia.',
        routerLink: ['/genetic', 'create'],
        routerLinkActiveOptions: {},
        styleClass: 'bg-module-a-base-color text-white',
        iconClass: 'text-white'
      },
      {
        id: 'mie_denunce',
        icon: 'folder_open',
        label: this.translateService.translate(this.translateItems, 'menu.miedenunce') , // 'Le mie denunce',
        title: this.translateService.translate(this.translateItems, 'menu.consultadenunce'), //'Consulta le tue denunce'
        routerLink: ['/genetic', 'search'],
        queryParams: { 'id': 'APERTE' },
        routerLinkActiveOptions: { queryParams: 'exact' }
      },
      {
        id: 'autorizzazioni_stallone',
        icon: 'check',
        label: this.translateService.translate(this.translateItems, 'menu.autorizzazionistallone'), // 'Autorizzazioni stallone',
        title: this.translateService.translate(this.translateItems, 'menu.denuncestalloni'), 
        routerLink: ['/genetic', 'search'],
        queryParams: { 'id': 'IN_ATTESA_CONF' },
        routerLinkActiveOptions: { queryParams: 'exact' }
      },
    ];

    this.items = [
      {
        label: this.translateService.translate(this.translateItems, 'sidebar.moda'),
        items: [
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.datemontanascita'),
            path: 'dates',
          },
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.fattrice'),
            path: 'fattrice',
          },
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.stallone'),
            path: 'stallone',
          },
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.cucciolata'),
            path: 'cucciolata',
          },
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.riepilogodati'),
            path: 'summary',
          },
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.anomaly'),
            path: 'anomaly',
          },
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.pagamentoa'),
            path: 'payment',
          }
        ]
      },
      {
        label: this.translateService.translate(this.translateItems, 'sidebar.modb'),
        items: [
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.riepilogodatimoda'),
            path: 'summary',
          },
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.daticucciolata'),
            path: 'litter_data',
          },
          {
            label: this.translateService.translate(this.translateItems, 'sidebar.pagamentob'),
            path: 'payment',
          },
        ]
      },
    ];

  }

  analyzeUrl() {
  }

  isActive(path: string) {
    return true;
  }

  openAddModuleA(): void {
    this.router.navigate(['/genetic', 'create']);

    this.hideSidebar.emit(true);
  }

  openHelp(): void {
    window.open(environment.urlHelpGuide, '_blank', 'noopener noreferrer');
  }

  private manageSidebar(url: string, showHideModuloB: boolean = true) {

    
    this.showModuloBPart = true;

    if (url.match(/^\/genetic\/details\/(\w+)\/moduleA/)) {
      let indexfattriceIndex = url.indexOf('idpropriefatt=');
      if(indexfattriceIndex != -1) {
        let idPropFattrice = url.substring(indexfattriceIndex + 14);
        this.idPropFattrice = idPropFattrice;
      }
      const match = url.match(/^\/genetic\/details\/(\w+)\/moduleA/);
      this.geneticId = match[1];
      this.mode = 'moduleA';
      this.activeIndex = 0;
      this.showModuloBPart = showHideModuloB;
    } else if (url.match(/^\/genetic\/details\/(\w+)\/moduleB/)) {
      const match = url.match(/^\/genetic\/details\/(\w+)\/moduleB/);
      this.geneticId = match[1];
      this.mode = 'moduleB';
      this.activeIndex = 1;
    }
    else if (url.match(/^\/genetic\/details\/(\w+)\/draft-moduleB/)) {
      const match = url.match(/^\/genetic\/details\/(\w+)\/draft-moduleB/);
      this.geneticId = match[1];
      this.mode = 'moduleB';
      this.activeIndex = 1;
      this.showModuloBPart = false;
    }
    else if (url == '/genetic/create') {
      this.geneticId = '';
      this.mode = '';
      this.activeIndex = -1;
      this.hideCreateModuloA = true;
    } else {
      this.geneticId = '';
      this.mode = '';
      this.activeIndex = -1;
      this.hideCreateModuloA = false;
    }
  }

  logout() {
    this.oidcSecurityService.logoff().subscribe();
  }

  clickedOnEntry() {
    this.hideSidebar.emit(true);
  }

  setLanguage(langId: number) {
    this.languageCurrent = this.languages.find(language => language.id == langId);

    this.moduloABService.utente.langId = this.languageCurrent.id;
    this.moduloABService.upsertUtente(this.moduloABService.utente).pipe(
      takeUntil(this.destroy$)
    ).subscribe();
  }

}
