export enum GENETIC_STATUS {
  draft = 'draft',
  confirmed = 'confirmed',
  pending_confirmation = 'pending_confirmation',
  waiting_solution = 'waiting_solution'
}

export enum GENETIC_TYPE {
  moduleA = 'moduleA',
  moduleB = 'moduleB'
}

