<ng-container *ngIf="T$ | async as T">
  <div class="app-sidebar w-17rem h-full" [class.w-17rem]="!isInSidebar" [class.w-full]="isInSidebar"
    *ngIf="(allevatore$ | async)">
    <!-- Navigation menu -->
    <div class="custom-shadow-2 lg:border-round overflow-hidden">
      <div class="app-menu-separator p-3 flex align-items-center gap-2 bg-light-blue lg:hidden cursor-pointer"
        (click)="op.toggle($event, targetMenu)">
        <div class="avatar flex justify-content-center align-items-center line-height-1 custom-shadow-1 flex-shrink-0"
          [pTooltip]="labelTipoSocio" tooltipPosition="right">
          <i class="pi pi-user"></i>
        </div>

        <div class="flex justify-content-between font-semibold gap-2 align-items-center flex-grow-1">
          <div class="line-height-1">{{ nomeCognomeAllevatore }}</div>

          <i class="pi pi-angle-down" #targetMenu></i>
        </div>
      </div>

      <div class="app-menu-separator p-3 flex align-items-center gap-2 bg-light-blue lg:hidden  cursor-pointer"
        (click)="opLanguage.toggle($event, targetLanguage)">
        <div class="flex justify-content-center align-items-center line-height-1 flex-shrink-0">
          <img *ngIf="languageCurrent.lang" src="assets/icons/Flag/{{languageCurrent.lang}}.png" width="32" height="32">
        </div>
        <div class="flex justify-content-between font-semibold gap-2 flex-grow-1">
          <div class="line-height-1">{{languageCurrent.des}}</div>
          <i class="pi pi-angle-down" #targetLanguage></i>
        </div>
      </div>

      <div class="menu-item flex align-items-center p-3 gap-2 border-noround cursor-pointer"
        [class.lg:border-round-top]="first" [class.lg:border-noround]="last" [class.app-menu-separator]="!last"
        [ngClass]="menu.styleClass" *ngFor="let menu of navigationItems; let last = last; let first = first;"
        [routerLink]="menu.routerLink" router routerLinkActive="active" (click)="clickedOnEntry()"
        [routerLinkActiveOptions]="menu.routerLinkActiveOptions" [queryParams]="menu.queryParams">
        <app-custom-icon class="text-blue-600 menu-icon" [ngClass]="menu.iconClass"
          [name]="menu.icon"></app-custom-icon>

        <span class="line-height-1">{{menu.label}}</span>
      </div>

      <!-- <div class="menu-item flex align-items-center p-3 gap-2 border-noround cursor-pointer app-menu-separator-top"
      (click)="openHelp()">
      <app-custom-icon class="text-blue-600 menu-icon" name="help"></app-custom-icon>

      <span class="line-height-1">Guida per l'utente</span>
    </div> -->
    </div>

    <!-- Actions menu -->

    <!-- <div class="px-3 mt-3">
    <button pButton type="button" icon="pi pi-question-circle"
      class="w-full border-none custom-shadow-1 app-bg-primary mt-2" (click)="openHelp()" label="Guida per l'utente">
    </button>
  </div> -->
    <div class="custom-shadow-2 lg:border-round overflow-hidden mt-5">
      <div class="menu-item flex align-items-center p-3 gap-2 border-noround cursor-pointer" (click)="openHelp()">
        <app-custom-icon class="text-blue-600 menu-icon" name="help"></app-custom-icon>

        <span class="line-height-1">
          {{ T | translateItem: 'menu.guidautente'}}
        </span>
      </div>
    </div>

    <!-- Genetic menu -->
    <div class="custom-shadow-2 lg:border-round">
      <div *ngIf="geneticId" class="mt-4 px-3 py-3 bg-blue-600 text-white lg:border-round-top">
        ID {{geneticId}}
      </div>

      <p-accordion *ngIf="geneticId" [activeIndex]="activeIndex" (activeIndexChange)="activeIndex=$event"
        class="lg:border-round-bottom">
        <p-accordionTab [headerStyleClass]="'custom-menu-header module-a ' + (activeIndex === 0 ? 'active' : '')"
          contentStyleClass="p-0 bg-light-blue">
          <ng-template pTemplate="icon"></ng-template>

          <ng-template pTemplate="header">
            <div>{{ T | translateItem: 'sidebar.moda'}}
            </div>
          </ng-template>

          <ng-template pTemplate="content">
            <ng-container *ngFor="let item of items[0].items">
              <ng-container *ngIf="item.path != 'anomaly' || showAnomalie">
                <div class="a-menu-item" [routerLink]="'/genetic/details/' + geneticId + '/moduleA'"
                  [queryParams]="{ tab: item.path, idpropriefatt: idPropFattrice }" routerLinkActive="active">
                  {{item.label}}
                </div>
              </ng-container>
            </ng-container>

          </ng-template>
        </p-accordionTab>

        <p-accordionTab [headerStyleClass]="'custom-menu-header module-b ' + (activeIndex === 1 ? 'active' : '')"
          *ngIf="showModuloBPart" contentStyleClass="p-0 bg-light-blue">
          <ng-template pTemplate="icon"></ng-template>

          <ng-template pTemplate="header">
            <div>{{ T | translateItem: 'sidebar.modb'}}</div>
          </ng-template>

          <ng-template pTemplate="content">
            <div *ngFor="let item of items[1].items" class="b-menu-item"
              [routerLink]="'/genetic/details/' + geneticId + '/moduleB/' + item.path" routerLinkActive="active">
              {{item.label}}
            </div>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <p-overlayPanel #op>
    <button pButton type="button" (click)="logout()" label="Logout"></button>
  </p-overlayPanel>
  <p-overlayPanel #opLanguage>
    <div class="flex flex-column gap-2" *ngIf="languages$ | async as languages">
      <p-button *ngFor="let language of languages" [text]="true"
        (click)="setLanguage(language.id); opLanguage.toggle($event);">
        <img src="assets/icons/Flag/{{language.lang}}.png" alt="Image" width="32">&nbsp;{{language.des}}
      </p-button>
    </div>
  </p-overlayPanel>
</ng-container>