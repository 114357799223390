import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/modules/shared/components/base/base.component';

@Component({
  selector: 'app-operation-status',
  templateUrl: './operation-status.component.html',
  styleUrls: ['./operation-status.component.scss']
})
export class OperationStatusComponent extends BaseComponent implements OnInit, OnDestroy {
  get severityClass(): string {
    return `${this.classPrefix}-${this.severity}`
  }

  @Input()
  classPrefix: string = 'app';

  @Input()
  message: string = '';

  @Input()
  icon: string = null;

  @Input()
  severity: 'info' | 'success' | 'warning' | 'error' = 'info';

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
