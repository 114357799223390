import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { TranslateService } from 'src/app/services/translate.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  T$: Observable<any>;
  translateItems: any;

  constructor(private translateService: TranslateService){
  }

  ngOnInit(): void {
    this.T$ = this.translateService.translatedItems$(['footer.']).pipe(
      tap(items => {
        this.translateItems = items;
      })
    );
  }
}
