import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { ModuloABService } from './moduloab.service';
import { valueIsDate } from '../modules/shared/helpers/util';

export type Resources = { [key: string]: string }[];

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private cacheItems: { [key: string]: any } = {};
  private cacheObservable: { [key: string]: Observable<Resources> } = {};

  
  constructor(
    private moduloABService: ModuloABService
  ) { }

  translatedItems$(startWith: string[], lang?: string): Observable<Resources> {    
    const strStartWith = startWith.join('|');
    
    if (this.cacheItems[strStartWith]) {
      return of(this.cacheItems[strStartWith]);
    }
    else if (!this.cacheObservable[strStartWith]) {
       this.cacheObservable[strStartWith] = this.moduloABService.getLabels(startWith, lang)
        .pipe(
          tap(objRes => {
            this.cacheItems[strStartWith] = objRes
          }),
          share(),
          finalize(() => this.cacheObservable[strStartWith] = null)
        );
    }

    return this.cacheObservable[strStartWith];
  }

  /**
   * Search key in translateItems and return the value.
   * Key can be formatted as 'key1|key2|...keyn'
   * in this way every single key will be translated
   * and the result will be the join of each single translation separated by the pipe character
   *
   * @param translateItems
   * @param key
   * @param args
   * @returns
   */
  public translate(translateItems: Resources, key: string, args?: any): string {
    if (!translateItems) {
      return undefined;
    }

    if (!key) {
      return '';
    }

    const keys = key.split('|');
    const arrResult: string[] = [];
    keys.forEach(key => {
      let val = translateItems[key.toLowerCase()];
      if (!val) {
        val = `!!!${key}!!!`;
      }
      arrResult.push(val);
    });

    let result = arrResult.join('|');
    if (args) {
      Object.keys(args).forEach(key => {
        result = result.replace(`{{${key}}}`, valueIsDate(args[key]) ? new Date(args[key])?.toLocaleDateString() : args[key]);
      });
    }

    return result;
  }
}
