<ng-container *ngIf="T$ | async as T">
  <div class="p-5 overflow-auto" style="height: 100vh; height: 100dvh;" [formGroup]="basicForm">
    <div class="flex justify-content-center" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="genetic-modulo-b p-4 lg:px-0 lg:py-3" *ngIf="!loading && !errorRetrieveModA && id">
      <div class="flex gap-3 align-items-center justify-content-center lg:justify-content-start text-blue-600 mb-2">
        <div class="text-blue-600 font-bold text-sm lg:text-3xl line-height-1">{{ T | translateItem: 'modb.id'}} {{id}}
        </div>
        <div class="text-indigo-400 text-sm lg:text-3xl line-height-1">{{T | translateItem: 'moda.modelloa'}}</div>
      </div>
      <div class="p-divider"></div>
      <ng-template [ngTemplateOutlet]="buttonApprove">
      </ng-template>
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4">
          <div class="text-blue-600 font-semibold mb-2">
            {{T | translateItem: 'moda.datamontanascita'}}
          </div>
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round">
            <div class="grid -mb-2">
              <div class="col-12 lg:col-6 mb-2">
                <app-custom-input label="{{T | translateItem: 'modb.datanascita'}}" formControlName="dataDiNascita"
                  [formGroup]="basicForm" ngDefaultControl></app-custom-input>
              </div>
              <div class="col-12 lg:col-6 mb-2">
                <app-custom-input label="{{T | translateItem: 'modb.datamonta'}}" formControlName="dataDiMonta"
                  [formGroup]="basicForm" ngDefaultControl></app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{ T | translateItem: 'moda.dateinserimentopagamento' }}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round">
            <div class="grid -mb-2">
              <div class="col-12 lg:col-6 mb-2">
                <label class="block text-sm text-blue-600 font-semibold mb-1">
                  <!-- Data di inserimento --> {{ T | translateItem: 'moda.datainserimento' }}
                </label>
                <input type="text" pInputText class="p-inputtext-sm w-full opacity-100 custom-input-bg"
                  [value]="denuncia.datadenuncia | getOnlyDateFromString" [disabled]="true" />
              </div>
              <div class="col-12 lg:col-6 mb-2">
                <label class="block text-sm text-blue-600 font-semibold mb-1">
                  <!-- Data di pagamento --> {{ T | translateItem: 'moda.datapagamento' }}
                </label>
                <input type="text" pInputText class="p-inputtext-sm w-full opacity-100 custom-input-bg"
                  [value]="denuncia.ricevuta?.dataricevuta ? (denuncia.ricevuta?.dataricevuta | getOnlyDateFromString) : 'N/A'"
                  [disabled]="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{ T | translateItem: 'moda.delegazioneriferimento' }}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round">
            <div class="grid">
              <div class="col-12 xl:col-6">
                <label class="block text-sm text-blue-600 font-semibold mb-1">
                  <!-- Delegazione di riferimento --> {{ T | translateItem: 'moda.delegazioneriferimento' }}
                </label>
                <input type="text" pInputText class="p-inputtext-sm w-full opacity-100 custom-input-bg"
                  [value]="delegazione?.delegazione" disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{ T | translateItem: 'moda.datiallevatorefattrice' }}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round">
            <div class="grid">
              <!-- <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.codicefiscale' }}" formControlName="taxId"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div> -->
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.nominativo' }}" formControlName="firstName"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <!-- <div class="col-12 xl:col-6" *ngIf="basicForm.get('memberType').value">
                <app-custom-input label="{{ T | translateItem: 'moda.tipologiasocio' }}" formControlName="memberType"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.anno' }}" formControlName="year"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.numerotessera' }}" formControlName="cardNumber"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div> -->
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.affisso' }}" formControlName="affix"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.email' }}" formControlName="email"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.cellulare' }}" formControlName="mobilePhone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.telefono' }}" formControlName="phone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{ T | translateItem: 'moda.daticontactfattrice' }}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round">
            <div class="grid">
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.email' }}" formControlName="email"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.cellulare' }}" formControlName="mobilePhone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
            </div>
            <div class="grid">
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.telefono' }}" formControlName="phone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{ T | translateItem: 'moda.datipropstallone' }}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round">
            <div class="grid">
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.codicefiscalesemplice' }}"
                  formControlName="taxIdPropStallone" [formGroup]="basicForm" ngDefaultControl
                  [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.nominativo' }}" formControlName="firstNamePropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <!-- <div class="col-12 xl:col-6" *ngIf="basicForm.get('memberTypePropStallone').value">
                <app-custom-input label="{{ T | translateItem: 'moda.tipologiasocio' }}" formControlName="memberTypePropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.anno' }}" formControlName="yearPropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.numerotessera' }}" formControlName="cardNumberPropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div> -->
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.affisso' }}" formControlName="affixPropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.email' }}" formControlName="emailPropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.cellulare' }}"
                  formControlName="mobilePhonePropStallone" [formGroup]="basicForm" ngDefaultControl
                  [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.telefono' }}" formControlName="phonePropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{ T | translateItem: 'moda.recapitoallevatorefattrice' }}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round" [formGroup]="contactForm">
            <div class="grid">
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.via' }}" formControlName="street"
                  [formGroup]="contactForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.cap' }}" formControlName="postalCode"
                  [formGroup]="contactForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.citta' }}" formControlName="city"
                  [formGroup]="contactForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.provincia' }}" formControlName="province"
                  [formGroup]="contactForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
            </div>
          </div>
          <div class="flex align-items-center gap-3 mt-3">
            <div class="flex align-items-center gap-1">
              <p-radioButton name="sameAddressForIspezione" [disabled]="true" [value]="true"
                [(ngModel)]="sameAddressForIspezione" [ngModelOptions]="{standalone: true}" inputId="sameAddressForIspezione1"></p-radioButton>
              <label for="sameAddressForIspezione1" class="ml-2">{{ T | translateItem: 'moda.testo' }}</label>
            </div>
    
            <div class="flex align-items-center gap-1">
              <p-radioButton name="sameAddressForIspezione" [disabled]="true" [value]="false"
                [(ngModel)]="sameAddressForIspezione" [ngModelOptions]="{standalone: true}" inputId="sameAddressForIspezione2"></p-radioButton>
              <label for="sameAddressForIspezione2" class="ml-2">{{ T | translateItem: 'moda.recapitodiversoresidenza.altroindirizzo'
                }}</label>
            </div>
          </div>
        </div>
      </div>
    
      <ng-container *ngIf="sameAddressForIspezione == false">
        <div class="p-divider"></div>
        <div class="grid -mb-2">
          <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
            {{ T | translateItem: 'moda.recapitocontrollocucciolata' }}
          </div>
          <div class="col-12 lg:col-10">
            <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round" [formGroup]="contactFormOptional">
              <div class="grid">
                <div class="col-12 xl:col-6">
                  <app-custom-input label="{{ T | translateItem: 'moda.presso' }}" formControlName="presso"
                    [formGroup]="contactFormOptional" ngDefaultControl [disabledStyle]="true"></app-custom-input>
                </div>
                <div class="col-12 xl:col-6">
                  <app-custom-input label="{{ T | translateItem: 'moda.via' }}" formControlName="street"
                    [formGroup]="contactFormOptional" ngDefaultControl [disabledStyle]="true"></app-custom-input>
                </div>
                <div class="col-12 xl:col-6">
                  <app-custom-input label="{{ T | translateItem: 'moda.citta' }}" formControlName="city"
                    [formGroup]="contactFormOptional" ngDefaultControl [disabledStyle]="true"></app-custom-input>
                </div>
                <div class="col-12 xl:col-6">
                  <app-custom-input label="{{ T | translateItem: 'moda.cap' }}" formControlName="postalCode"
                    [formGroup]="contactFormOptional" ngDefaultControl [disabledStyle]="true"></app-custom-input>
                </div>
                <div class="col-12 xl:col-6">
                  <app-custom-input label="{{ T | translateItem: 'moda.provincia' }}" formControlName="province"
                    [formGroup]="contactFormOptional" [maxlength]="2" ngDefaultControl
                    [disabledStyle]="true"></app-custom-input>
                </div>
                <div class="col-12 xl:col-6">
                  <app-custom-input label="{{ T | translateItem: 'moda.email' }}" formControlName="email"
                    [formGroup]="contactFormOptional" ngDefaultControl [disabledStyle]="true"></app-custom-input>
                </div>
                <div class="col-12 xl:col-6">
                  <app-custom-input label="{{ T | translateItem: 'moda.cellulare' }}" formControlName="mobilePhone"
                    [formGroup]="contactFormOptional" ngDefaultControl [disabledStyle]="true"></app-custom-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{ T | translateItem: 'moda.contatti' }}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round">
            <div class="grid">
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.email' }}" formControlName="emailPropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.telefono' }}" formControlName="phonePropStallone"
                  [formGroup]="basicForm" ngDefaultControl [disabledStyle]="true"></app-custom-input>
              </div>
            </div>
            <div class="grid">
              <div class="col-12 xl:col-6">
                <app-custom-input label="{{ T | translateItem: 'moda.cellulare' }}"
                  formControlName="mobilePhonePropStallone" [formGroup]="basicForm" ngDefaultControl
                  [disabledStyle]="true"></app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{T | translateItem: 'moda.fattrice'}}
        </div>
        <div class="col-12 lg:col-10">
          <scheda-cane [cane]="fattrice" [sex]="'F'" [mostraProprietario]="false"></scheda-cane>
        </div>
      </div>
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{T | translateItem: 'moda.stallone'}}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 mb-3 background-stallone custom-shadow-1 border-round">
            <div class="grid mb-2">
              <div class="col-12 xl:col-6">
                <div class="flex align-items-center gap-2">
                  <img src="assets/icons/zampa.svg" />
                  <div class="text-blue-600 font-semibold">{{stallone.nome}}</div>
                </div>
              </div>

              <div class="col-12 xl:col-6">
                <div class="flex align-items-center gap-2">
                  <span class="text-sm">{{T | translateItem: 'modb.datanascita'}}</span>
                  <span class="font-semibold">{{stallone.datanascita}}</span>
                </div>
              </div>
            </div>
            <div class="grid">
              <div class="col">
                <div class="grid">
                  <div class="col-12 xl:col-6">
                    <div class="text-sm">{{T | translateItem: 'moda.niscrizionestallone'}}</div>
                    <div class="font-semibold break-word">{{stallone.codice}}</div>
                  </div>
                  <div class="col-12 xl:col-6">
                    <div class="text-sm">{{T | translateItem: 'moda.stallonerazza'}}</div>
                    <div class="font-semibold break-word">{{stallone.razza}}</div>
                  </div>
                  <div class="col-12 xl:col-6">
                    <div class="text-sm">{{T | translateItem: 'moda.stallonemantello'}}</div>
                    <div class="font-semibold break-word">{{stallone.mantello}}</div>
                  </div>
                  <div class="col-12 xl:col-6" *ngIf="stallone.idmarca">
                    <div class="text-sm">{{T | translateItem: 'cane.tatuaggio'}}</div>
                    <div class="font-semibold break-word">{{ stallone.idmarca }}</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="grid mb-2">
                  <div class="col-12 xl:col-6">
                    <div class="text-sm">{{T | translateItem: 'modb.stallonemicrochip'}}</div>
                    <div class="font-semibold break-word">{{stallone.chip}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-divider"></div>
      <div class="grid -mb-2">
        <div class="col-12 lg:col-2 lg:pl-4 text-blue-600 font-semibold">
          {{T | translateItem: 'moda.riepilogocucciolata'}}
        </div>
        <div class="col-12 lg:col-10">
          <div class="custom-card p-3 bg-light-blue custom-shadow-1 border-round" [formGroup]="basicForm">
            <div class="grid -mb-2">
              <div class="col-6 lg:col-3 mb-2">
                <app-custom-input type="number" label="{{T | translateItem: 'moda.nfemmine'}}" formControlName="female"
                  [formGroup]="basicForm" placeholder="0" [disabledStyle]="true" ngDefaultControl></app-custom-input>
              </div>
              <div class="col-6 lg:col-3 mb-2">
                <app-custom-input type="number" label="{{T | translateItem: 'moda.nmaschi'}}" formControlName="male"
                  [formGroup]="basicForm" placeholder="0" [disabledStyle]="true" ngDefaultControl></app-custom-input>
              </div>
              <div class="col-6 lg:col-3 mb-2">
                <app-custom-input type="number" label="{{T | translateItem: 'moda.ndeceduti'}}"
                  formControlName="deceased" [formGroup]="basicForm" placeholder="0" [disabledStyle]="true"
                  ngDefaultControl></app-custom-input>
              </div>
              <div class="col-6 lg:col-3 mb-2">
                <app-custom-input type="number" label="{{T | translateItem: 'moda.ntotale'}}" formControlName="total"
                  [formGroup]="basicForm" placeholder="0" [disabledStyle]="true" ngDefaultControl></app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-divider"></div>
      <ng-template [ngTemplateOutlet]="buttonApprove">

      </ng-template>
    </div>
  </div>
  <ng-template #buttonApprove>
    <div class="grid">
      <div class="col-12 lg:col-2 lg:pl-4">
      </div>
      <div class="col-12 lg:col-10">
        <div class="flex flex-row justify-content-between align-items-center">
          <div>
            <button pButton type="button" class="p-button-danger" [loading]="confirmingDenuncia"
              (click)="refuseDenuncia()" label="{{T | translateItem: 'moda.rifiuta.btn'}}"></button>
          </div>
          <div>
            <button pButton type="button" class="p-button-success" [loading]="confirmingDenuncia"
              (click)="approveDenuncia()" label="{{T | translateItem: 'moda.approva.btn'}}"></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <p-dialog [(visible)]="showDialogRifiuto" [modal]="true" [closeOnEscape]="false" [closable]="false"
    styleClass="app-modal-window-lg">
    <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{T | translateItem: 'moda.rifiuta.btn'}}</span>
    </ng-template>

    <ng-template pTemplate="content">
      <p>
        {{T | translateItem: 'mes.rifiuto.1'}}
      </p>
      <div>
        <textarea rows="5" style="width:100%" pInputTextarea [(ngModel)]="motivazioneRifiuto"></textarea>
      </div>
      <div *ngIf="showReadsonNotValid">
        {{T | translateItem: 'mes.rifiutononvalido.1'}}
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-between gap-2">
        <button pButton type="button" (click)="showDialogRifiuto = false; motivazioneRifiuto = undefined;"
          [loading]="confirmingDenuncia" [disabled]="checkingCode"
          label="{{T | translateItem: 'moda.annulla.btn'}}"></button>

        <button pButton type="button" (click)="rifiutaDenunciaDefinitivo()" [loading]="confirmingDenuncia"
          [disabled]="askingNuovoCodice" label="{{T | translateItem: 'moda.ok.btn'}}"></button>
      </div>
    </ng-template>
  </p-dialog>
  <p-dialog [(visible)]="visibleDialogCode" [modal]="true" [closeOnEscape]="false" [closable]="false"
    styleClass="app-modal-window-lg">
    <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{T | translateItem: 'moda.attenzione'}}</span>
    </ng-template>

    <ng-template pTemplate="content">
      <p>
        {{T | translateItem: 'mes.1'}}
      </p>
      <div>
        <input type="text" pInputText class="p-inputtext-sm w-full" [(ngModel)]="currentCode"
          [placeholder]="T | translateItem: 'mes.inseriscicodice.placeholder'" />
      </div>
      <div *ngIf="showCodiceNonValido">
        {{T | translateItem: 'mes.2'}}
        <!-- Codice non valido. Controllare la correttezza del dato inserito. Il codice dura un'ora dall'invio della richiesta.
      Cliccare su "invia altro codice" se quello conosciuto non funziona. -->
      </div>
      <div *ngIf="showCodiceReinviato">
        {{T | translateItem: 'mes.3'}}
        <!-- Un nuovo codice è stato inviato al cellulare collegato a questa richiesta -->
      </div>
      <div *ngIf="cannotReSendCodeReason">
        {{cannotReSendCodeReason}}
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-between gap-2">
        <button pButton type="button" (click)="richiediNuovoCodice()" size="small" [loading]="askingNuovoCodice"
          [disabled]="checkingCode" label="{{T | translateItem: 'mes.nuovootp.btn'}}"></button>

        <button pButton type="button" (click)="tryToValidateCode()" size="small" [loading]="checkingCode"
          [disabled]="askingNuovoCodice" label="{{T | translateItem: 'mes.ok.btn'}}"></button>
      </div>
    </ng-template>
  </p-dialog>
  <p-toast [key]="Constants.KeyPToastFastApprove"></p-toast>
  <p-confirmDialog [dismissableMask]="true" [key]="Constants.KeyFastApproveConfirmDialog"></p-confirmDialog>
</ng-container>