<ng-container *ngIf="!showOnlyDenunceDaConfermare">
  <ng-container *ngIf="(isAuthenticated$ | async) as authStatus">
    <ng-container *ngIf="authStatus.isAuthenticated">
      <div class="flex flex-column overflow-auto" style="height: 100vh; height: 100dvh;">
        <app-header></app-header>

        <div class="flex flex-grow-1">
          <div class="flex flex-grow-1 main-content overflow-x-hidden overflow-y-auto">
            <app-sidebar class="hidden lg:block sticky top-0 lg:p-3 lg:pr-0"></app-sidebar>

            <div class="flex-1 lg:px-3">
              <router-outlet></router-outlet>
            </div>
          </div>

        </div>

        <app-footer></app-footer>
      </div>

      <ng-container *ngIf="!initialized">
        <div class="flex justify-content-center">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </ng-container>
      <p-toast [key]="Constants.KeyPToastApp"></p-toast>
      <p-confirmDialog [dismissableMask]="true"></p-confirmDialog>
    </ng-container>

  </ng-container>
</ng-container>

<ng-container *ngIf="showOnlyDenunceDaConfermare">
  <router-outlet></router-outlet>
</ng-container>