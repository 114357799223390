import { NgModule } from '@angular/core';
import { CardModule } from 'primeng/card';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CardModule,
    SharedModule
  ]
})
export class HomeModule { }
