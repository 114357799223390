import { Message } from "primeng/api";
import { ModuloA, ModuloAUpdateDocMode } from "./interfaces/scp";
import { Allevatore, ModuloAEnci, RecapitoControlloCucciolata, StalloneFattrice } from "./interfaces/enci";
import * as moment from "moment";

export function getLanguageFromBrowser(): string {

  let lang = navigator.language;
  if (lang == null || lang == undefined || lang == '') {
    lang = 'it-IT';
  }

  if(lang.length == 2) {//edge restituisce solo la lingua, senza il country
    if(lang == 'it' || lang == 'fr' || lang == 'de' || lang == 'es') {
      lang = lang + '-' + lang.toUpperCase();
    } else if (lang == 'en') {
      lang = lang + '-GB';
    }
  }

  return lang;
}


export function createMessageToastPrimeNg(keyToast: string, textMessage: string, header: string, severity: 'error' | 'warn' | 'success' = 'error', sticky: boolean = false): Message {
  return {
    key: keyToast,
    severity: severity,
    summary: header,
    detail: textMessage,
    sticky: sticky
  };
}

export function isStalloneEstero(stallone: StalloneFattrice) {
  return stallone.tpcane == 'ES' && (stallone.tparrivo == 'EST' || stallone.tparrivo == 'GEN');
}

export function convertModuloAToDichiarazione(draft: ModuloA, stallone: StalloneFattrice, fattrice: StalloneFattrice, allevatore: Allevatore): ModuloAEnci {
  let totaleNati: number = 0;
  if (draft.numFemmine >= 0 && draft.numDeceduti >= 0 && draft.numMaschi >= 0) {
    totaleNati = draft.numFemmine + draft.numDeceduti + draft.numMaschi;
  }

  let recapitoExtra: RecapitoControlloCucciolata = undefined;

  if (draft.recapitoIspezione) {
    recapitoExtra = {
      cap: draft.recapitoIspezione.cap,
      indirizzo: draft.recapitoIspezione.indirizzo,
      localita: draft.recapitoIspezione.localita,
      provincia: draft.recapitoIspezione.provincia,
      delegazione: draft.recapitoIspezione.nomeDelegazione,
      nazione: '',
      note: '',
      presso: draft.recapitoIspezione.presso,
      cellulare: draft.recapitoIspezione.cellulare,
      email: draft.recapitoIspezione.email,
      delegazioneId: draft.recapitoIspezione.delegazioneId
    };
  }

  if(stallone) {
    stallone.telefonobisproprietario = draft.cellularePropStallone ? draft.cellularePropStallone : stallone.telefonobisproprietario;
    stallone.emailproprietario = draft.emailPropStallone ? draft.emailPropStallone : stallone.emailproprietario;
  }
  let breeder: ModuloAEnci = {
    datamonta: moment(draft.dataMonta as unknown as string, 'YYYY-MM-DD HH:mm:ssZ').format('DD/MM/YYYY HH:mm:ssZ'),
    datanascita: moment(draft.dataCucciolata as unknown as string, 'YYYY-MM-DD HH:mm:ssZ').format('DD/MM/YYYY HH:mm:ssZ'),
    anniallamontastallone: "0",
    anniallanascitafattrice: "0",
    datadenuncia: moment(draft.dtIns, 'YYYY-MM-DD HH:mm:ssZ').format('DD/MM/YYYY'),
    dataconfermastall: undefined,
    dataTermineUltimoModB: undefined,
    notaRifiutoStallone: undefined,
    dataidoneitaripro: undefined,
    dataregistrazione: undefined,
    delegazione: recapitoExtra ? recapitoExtra.delegazione : undefined,
    fattrice: fattrice,
    femmine_iscri: "0",
    femmine_vivi: draft.numFemmine?.toString(),
    fl_anomalia: undefined,
    flagcertfistoladermoide: undefined,
    flagcertifstall: undefined,
    flagcoronavirus: undefined,
    flagesitoctrl: undefined,
    flagfirmastall: undefined,
    flagmontafortuita: undefined,
    flagstazionemonta: draft.stalloneMontaInItalia ? 'S' : 'N',
    flcertidoneitaripro: undefined,
    flInsertModB: undefined,
    flcertidoneitariprostall: undefined,
    flconfermatopropriestall: undefined,
    flcontrollo: undefined,
    flPagatoEnci: undefined,
    flriproduttoreselestallone: draft.stalloneInseminaArtificale ? 'S' : 'N',
    genealogiacompletastall: undefined,
    iddelegazione: draft.recapitoIspezione ? undefined : draft.delegazioneId,
    iddenuncia: draft.enciDenunciaId,
    idstatomoda: '-2',
    idstatomodb: '-3',
    idvarietafattauto: undefined,
    idvarietastalauto: undefined,
    ismonitoraggio15: undefined,
    maschi_iscri: "0",
    maschi_vivi: draft.numMaschi?.toString(),
    proprieallamontastallone: {
      capDomicilio: undefined,
      capResidenza: undefined,
      cellulare: draft.cellularePropStallone ? draft.cellularePropStallone : stallone?.telefonobisproprietario,
      telefono: stallone?.telefonoproprietario,
      codicefiscale: stallone?.codicefiscaleproprietario,
      datadecesso: stallone?.datadeceduto,
      datanascita: stallone?.datanascita,
      dataprivacy: stallone?.dataprivacy,
      delegazione: undefined,
      email: draft.emailPropStallone ? draft.emailPropStallone : stallone?.emailproprietario,
      flagfirmaprivacy: undefined,
      flconsensoinvioinfo: stallone?.flconsensoinvioinfo,
      flconsensomarketing: stallone?.flconsensomarketing,
      flconsensorecapiti: stallone?.flconsensorecapiti,
      id: stallone?.idproprietario,
      idallevamentoassociato: undefined,
      iddelegazione: stallone?.iddelegazione,
      idregioneDomicilio: undefined,
      indirizzoDomicilio: stallone?.indirizzoproprietario,
      indirizzoResidenza: stallone?.indirizzoproprietario,
      isAllevamento: undefined,
      isEsperto: undefined,
      isPagatoTessera: undefined,
      isProprietario: undefined,
      isSocioAggregato: undefined,
      isSocioAllevatore: undefined,
      localitaDomicilio: stallone?.localitaproprietario,
      localitaResidenza: stallone?.localitaproprietario,
      luogonascita: undefined,
      nazioneDomicilio: stallone?.nazioneproprietario,
      nazioneResidenza: stallone?.nazioneproprietario,
      nome: stallone?.nomeproprietario,
      partitaiva: undefined,
      provinciaDomicilio: stallone?.provinciaproprietario,
      provinciaResidenza: stallone?.provinciaproprietario,
      regioneDomicilio: undefined,
      sesso: undefined,
      tipoanagrafica: undefined,
      tiposocio: undefined,
      affisso: undefined,
      annotessera: undefined,
      nrtessera: undefined
    },
    proprieallanascitafattrice: {
      capDomicilio: allevatore.anagrafica.capDomicilio,
      capResidenza: allevatore.anagrafica.capResidenza,
      cellulare: fattrice?.telefonobisproprietario,
      telefono: fattrice?.telefonoproprietario,
      codicefiscale: fattrice?.codicefiscaleproprietario,
      datadecesso: fattrice?.datadeceduto,
      datanascita: fattrice?.datanascita,
      dataprivacy: fattrice?.dataprivacy,
      delegazione: undefined,
      email: fattrice?.emailproprietario,
      flagfirmaprivacy: undefined,
      flconsensoinvioinfo: fattrice?.flconsensoinvioinfo,
      flconsensomarketing: fattrice?.flconsensomarketing,
      flconsensorecapiti: fattrice?.flconsensorecapiti,
      id: fattrice?.idproprietario,
      idallevamentoassociato: undefined,
      iddelegazione: fattrice?.iddelegazione,
      idregioneDomicilio: undefined,
      indirizzoDomicilio: fattrice?.indirizzoproprietario,
      indirizzoResidenza: fattrice?.indirizzoproprietario,
      isAllevamento: undefined,
      isEsperto: undefined,
      isPagatoTessera: undefined,
      isProprietario: undefined,
      isSocioAggregato: undefined,
      isSocioAllevatore: undefined,
      localitaDomicilio: fattrice?.localitaproprietario,
      localitaResidenza: fattrice?.localitaproprietario,
      luogonascita: undefined,
      nazioneDomicilio: fattrice?.nazioneproprietario,
      nazioneResidenza: fattrice?.nazioneproprietario,
      nome: fattrice?.nomeproprietario,
      partitaiva: undefined,
      provinciaDomicilio: fattrice?.provinciaproprietario,
      provinciaResidenza: fattrice?.provinciaproprietario,
      regioneDomicilio: undefined,
      sesso: undefined,
      tipoanagrafica: undefined,
      tiposoggetto: undefined,
      affisso: undefined,
      annotessera: undefined,
      nrtessera: undefined,
    },
    ricevuta: undefined,
    listino: undefined,
    stallone: stallone,
    statomoda: draft.stato,
    statomodb: "",
    tipocucciolata: undefined,
    tot_iscritti: '0',
    tot_morti: draft.numDeceduti?.toString(),
    tot_nati: totaleNati.toString(),
    tpcucciolata: undefined,
    controlloCucciolata: recapitoExtra,
    documenti: [],
    idDraft: draft.id.toString(),
    idRazzaDraft: draft.enciRazzaId,
    idRazzaFattriceDraft: draft.enciRazzaFattriceId,
    idRecapitoDraft: draft.recapitoIspezione?.id?.toString(),
    fattriceLoi: draft.fattriceLoi,
    fattriceMicrochip: draft.fattriceMicrochip,
    hasAnomalie: draft.hasAnomalie,
    recapitoUgualeResidenza: draft.recapitoUgualeResidenza
  };


  if (draft.fattriceSaluteDoc) {
    breeder.documenti.push({
      contenttype: 'application/pdf',
      filename: draft.fattriceSaluteDoc,
      idfamily: ModuloAUpdateDocMode.UpdateFattriceDoc.toString(),
      idfile: undefined,
      tipoinvio: undefined,
      tipoinvioconvert: undefined
    });
  }


  return breeder;

}

export function labelTipoSocio(allevatore: Allevatore) {
  
  let tipoSocio = 'tiposocio.nonsocio.title';
  if (allevatore.anagrafica.tiposocio == 'SI') {
    tipoSocio = 'tiposocio.socioallevatore.title'
  } else if (allevatore.anagrafica.tiposocio == 'SA') {
    tipoSocio = 'tiposocio.socioaggregato.title';
  }

  return tipoSocio;
}
// export function labelTipoSocio(allevatore: Allevatore, translateService: TranslateService){
//   let T$: Observable<any> = translateService.translatedItems$(['menu']).pipe(
//     tap(items => {
//       this.translateItems = items;
//     })
//   );

//   let tipoSocio = `${this.translateService.translate(this.translateitems, 'menu.nonsocio.title')}` // 'Non socio';
//   if (allevatore.anagrafica.tiposocio == 'SI') {
//     tipoSocio = `${this.translateService.translate(this.translateitems, 'menu.socioallevatore.title')}` ; // socio allevatore
//   } else if (allevatore.anagrafica.tiposocio == 'SA') {
//     tipoSocio = this.translateService.translate(this.translateitems, 'menu.socioaggregato.title'); // socio aggregato 
//   }


export function isNull(value: string) {
  return value == null || value == undefined || value == '' || value == 'null' || value == 'undefined' || value == 'NULL' || value == 'UNDEFINED';
}

export function recapitoIsEmpty(recapito: RecapitoControlloCucciolata) {
  return isNull(recapito.cap) && isNull(recapito.indirizzo) && isNull(recapito.localita) && isNull(recapito.provincia) && isNull(recapito.delegazione) && isNull(recapito.nazione) && isNull(recapito.note) && isNull(recapito.presso) && isNull(recapito.cellulare) && isNull(recapito.email);
}

export function adjustFieldsDenunceFromEnci(denunce: ModuloAEnci[]): ModuloAEnci[] {

  denunce.forEach((denuncia) => {
    denuncia.recapitoUgualeResidenza = !(denuncia.controlloCucciolata && !recapitoIsEmpty(denuncia.controlloCucciolata));
  });

  return denunce;
}

export function adjustFieldsDenunciaFromEnci(denuncia: ModuloAEnci): ModuloAEnci {
  denuncia.recapitoUgualeResidenza = !(denuncia.controlloCucciolata && !recapitoIsEmpty(denuncia.controlloCucciolata));
  return denuncia;
}