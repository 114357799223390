import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KeyFilterPattern } from 'primeng/keyfilter';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent {
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() value: string | number;
  @Input() formControlName: string;
  @Input() formGroup: FormGroup;
  @Input() required: boolean;
  @Input() isError: boolean;
  @Input() errorText: string;
  @Input() keyFilter: RegExp | KeyFilterPattern = undefined;
  @Input() type: string = 'text';
  @Input() disabledStyle: boolean = false;
  @Input() maxlength: number = undefined;
}
