<div
  class="app-home-card border-round p-card app-bg-home-card custom-shadow-2 flex flex-row-reverse justify-content-end  gap-3 cursor-pointer p-3 text-white h-full align-items-center lg:flex-column lg:align-items-start lg:justify-content-start"
  [ngClass]="styleClass" (click)="clieckEvent.emit()">
  <div>
    <div class="text-2xl">
      {{title}}
    </div>
    <div class="mt-1">
      {{message}}
    </div>
  </div>

  <div class="lg:flex lg:mt-auto lg:justify-content-end lg:w-full">
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>