<ng-container *ngIf="T$ | async as T">
  <ng-container *ngIf="hasFiscalCode$ | async; else no_codice_fiscale">
    <ng-container *ngIf="navigationItems$ | async as navigationItems">
      <div class="app-home-card-container mx-4 lg:mx-auto py-4 h-full">
        <ng-container *ngFor="let item of navigationItems">
          <app-home-card (click)="navigate(item.routerLink, item.queryParams, item.url)" [title]="item.label"
            [message]="item.title" [styleClass]="item.styleClass">
            <img [src]="'assets/icons/'+ item.icon + '.svg'" [title]="item.label + 'icona'" />
          </app-home-card>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #no_codice_fiscale>
    <div class="flex justify-center items-center h-full">
      <div class="text-center">
        <h1 class="text-2xl">{{ T | translateItem: 'menu.no_codice_fiscale' }}</h1>
      </div>
    </div>
  </ng-template>
</ng-container>
