import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs';
import { ModuloABService } from './services/moduloab.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { EnciApiService } from './services/enciapi.service';
import { Constants } from './modules/shared/shared.export-constants';
import { createMessageToastPrimeNg } from './modules/shared/shared.functions';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from './services/translate.service';
import * as Sentry from "@sentry/angular";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  T$: Observable<any>;
  translateItems: any;

  Constants = Constants;

  isAuthenticated$: Observable<AuthenticatedResult>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  initialized: boolean = false;
  showBlockedPopup: boolean = false;

  allevatoreNome: string;

  showOnlyDenunceDaConfermare: boolean = false;

  //  sidebarVisible$: Observable<boolean> = this.dataService.getSidebarVisibile();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private enciApiService: EnciApiService,
    private config: PrimeNGConfig,
    private oidcSecurityService: OidcSecurityService,
    private moduloABService: ModuloABService,
    private translateService: TranslateService,
    private dataService: ModuloABService
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.enciApiService.getObsErrorFromService().pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastApp,
        message, 
        this.translateService.translate(this.translateItems, 'err.errore'), "error", true));
    });

    this.dataService.getObsErrorFromServiceScp().pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastApp,
        message, 'Errore', "error", true));
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('fast-approve')) {
          this.showOnlyDenunceDaConfermare = true;
        } else {
          this.showOnlyDenunceDaConfermare = false;
          this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
          this.oidcSecurityService.checkAuth()
            .pipe(
              filter(loginResponse => loginResponse.isAuthenticated),
              switchMap(loginResponse => {
                Sentry.setUser({ email: loginResponse.userData.email, id: loginResponse.userData.sub });
                return this.moduloABService.getUtente();
              }),
              takeUntil(this.destroy$)
            ).subscribe();
        }
      }

      // Cancella gli eventuali toast presenti
      this.messageService.clear();
    });

    // this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.destroy$)).subscribe(() => {
    //   this.router.url.includes('home') ? this.dataService.setSidebarVisibile(false) : this.dataService.setSidebarVisibile(true);
    // });
    this.T$ = this.oidcSecurityService.isAuthenticated$.pipe(filter(authenticated => { return authenticated.isAuthenticated }),
      mergeMap(() => this.translateService.translatedItems$(['err.', 'app.', 'mes.'])),
      tap(items => {
        this.translateItems = items;
        this.enciApiService.initializeTranslation(items);
        this.config.setTranslation({
          "dayNamesMin": [
            this.translateService.translate(this.translateItems, 'app.do'),
            this.translateService.translate(this.translateItems, 'app.lu'),
            this.translateService.translate(this.translateItems, 'app.ma'),
            this.translateService.translate(this.translateItems, 'app.me'),
            this.translateService.translate(this.translateItems, 'app.gi'),
            this.translateService.translate(this.translateItems, 'app.ve'),
            this.translateService.translate(this.translateItems, 'app.sa')
          ],
          "monthNames": [
            this.translateService.translate(this.translateItems,'app.gennaio'),
            this.translateService.translate(this.translateItems,'app.febbraio'),
            this.translateService.translate(this.translateItems,'app.marzo'),
            this.translateService.translate(this.translateItems,'app.aprile'),
            this.translateService.translate(this.translateItems,'app.maggio'),
            this.translateService.translate(this.translateItems,'app.giugno'),
            this.translateService.translate(this.translateItems,'app.luglio'),
            this.translateService.translate(this.translateItems,'app.agosto'),
            this.translateService.translate(this.translateItems,'app.settembre'),
            this.translateService.translate(this.translateItems,'app.ottobre'),
            this.translateService.translate(this.translateItems,'app.novembre'),
            this.translateService.translate(this.translateItems,'app.dicembre'),
          ]
        });
      }), takeUntil(this.destroy$));
    this.T$.subscribe();
  }
}
