import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';


@Injectable()
export class ResultResponseInterceptor implements HttpInterceptor {

  constructor(
    private oidcSecurityService: OidcSecurityService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map(
          (httpEvent) => {
            return httpEvent;
          }
        ),
        catchError((errorResponse: HttpErrorResponse) => {
          if(errorResponse.status == 401)
          {
            this.logout();
          }
          else if(errorResponse.error){
            // TODO build and show application error
            // errorResponse.error can be converted to an instance of ResultMessage
          }
          else
          {
            // TODO build and show http error
          }
          return throwError(() => errorResponse);
        })
      );
  }

  private logout() {
    this.oidcSecurityService.logoff().subscribe();
  }

}
