import {NgModule} from '@angular/core';
import {AbstractSecurityStorage, AuthInterceptor, AuthModule, DefaultLocalStorageService, LogLevel, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader, StsConfigStaticLoader} from 'angular-auth-oidc-client';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { map } from 'rxjs';
import { buildServiceUrl } from '../services/moduloab.service';


// const stsAuthLoaderFactory = () => {
//     const config = buildOpenIdConfiguration(environment.identityUrl);
//     return new StsConfigStaticLoader(config);
// };

export const stsHttpAuthLoaderFactory = (httpClient: HttpClient) => {
    const config$ = httpClient.get(buildServiceUrl('config', 'settings'))
    .pipe(
        map((configSettings: any) => {
            return buildOpenIdConfiguration(configSettings.restIdentityUrl);          
        })
    );
    return new StsConfigHttpLoader(config$);
};
export const configId: string = 'encimoduloab';
export function buildOpenIdConfiguration(authorityUrl: string): OpenIdConfiguration
{
    const origin = window.location.origin;
    const config: OpenIdConfiguration = {
        authority: authorityUrl,
        configId: configId,
        clientId: 'encimoduloab',
        scope: 'openid offline_access roles profile email impersonation moduloab_api',
        responseType: 'code',
        redirectUrl: `${origin}/callback`,
        postLogoutRedirectUri: `${origin}/`,
        unauthorizedRoute: '/unauthorized',        
        autoUserInfo: true,
        secureRoutes: [`${authorityUrl}/`, `/${environment.restApiPrefix}/`, environment.baseUrlEnci ],
        silentRenew: true,
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,
        startCheckSession: true,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        logLevel: LogLevel.Warn
    };
    return config;
}

@NgModule({
    imports: [
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: stsHttpAuthLoaderFactory,
                deps: [ HttpClient ]
            }
        })
    ],
    providers: [
        { provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    exports: [AuthModule]
})
export class AuthConfigModule {
}
