import { Pipe, PipeTransform } from "@angular/core";
import { GENETIC_STATUS } from "./enums";
import { TranslateService } from "../../services/translate.service";

@Pipe({
    name: 'acronym'
})
export class AcronymPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string): string {

        if (value) {

            if (value.length <= 2) {
                return value.toUpperCase();
            }

            let words = value.split(" ");
            if (words.length > 1) {
                return (words[0].charAt(0) + words[1].charAt(0)).toUpperCase();

            } else {
                return value.substring(0, 2).toUpperCase();
            }
        }
        return "";
    }
}

@Pipe({ name: 'genetic_status' })
export class GeneticStatusPipe implements PipeTransform {
  transform(value: GENETIC_STATUS | string): string {
    if (value === GENETIC_STATUS.draft) {
      return 'Bozza';
    }
    if (value === GENETIC_STATUS.confirmed) {
      return 'Confermato';
    }
    if (value === GENETIC_STATUS.pending_confirmation) {
      return 'In attesa di conferma';
    }
    if (value === GENETIC_STATUS.waiting_solution) {
      return 'In attesa di soluzioni';
    }
    
    return 'Sconosciuta'
  }
}

@Pipe({ name:'getOnlyDateFromString' })
export class GetOnlyDateFromStringsPipe implements PipeTransform {
  transform(value:string): string {
    if(!value) {
      return "";
    }
    if(value.length >= 6) {
      return value.substring(0, 10);
    }
    return value
  }
}

@Pipe({
  name: 'translateItem'
})
export class TranslateItemPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  transform(translateItems: any[], key: string, args?: any): string {
    return this.translateService.translate(translateItems, key, args);
  }
}