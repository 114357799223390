import { Component, Input } from '@angular/core';
import { TranslateService } from '../../../../services/translate.service';
import { Observable, mergeMap, tap } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { getLanguageFromBrowser } from '../../shared.functions';
@Component({
	selector: 'scheda-cane',
	templateUrl: 'scheda-cane.component.html',
	styleUrls: ['./scheda-cane.component.scss']
})

export class SchedaCaneComponent {
	T$: Observable<any>;
	translateItems: any;

	@Input()
	cane: any;

	@Input()
	mostraProprietario: boolean = false;

	@Input()
	sex: string = 'M';
	constructor(private translateService: TranslateService, private oidcSecurityService: OidcSecurityService) {
	}
	ngOnInit() {
		this.T$ = this.oidcSecurityService.isAuthenticated$.pipe(mergeMap(authResult => {
			if (authResult.isAuthenticated) {
				return this.translateService.translatedItems$(['modb.', 'cane.']);
			} else {
				let lang = getLanguageFromBrowser();
				return this.translateService.translatedItems$(['modb.', 'cane.'], lang);
			}
		}), tap(items => {
			this.translateItems = items;
		}));

	}
}