export interface ModuloA {
    id?: number;
    dtIns?: string;
    enciDenunciaId?: string;
    enciModuloAId?: string;
    enciRazzaId?: string;
    enciRazzaFattriceId?: string;
    enciFattriceId?: string;
    enciStalloneId?: string;
    proprietarioCucciolataId?: string;
    delegazioneId?: string;
    dataMonta?: Date;
    dataCucciolata?: Date;
    fattriceMicrochip?: string;
    fattriceProprietarioId?: string;
    fattriceLoi?: string;
    fattriceSaluteDoc?: string;
    dataFattriceSaluteDoc?: Date;
    dataInsFattriceSaluteDoc?: Date,
    stalloneEsteroDoc?: string;
    dataInsStalloneEsteroDoc?: Date,
    fattriceFistolaDermoide?: boolean;
    stalloneFistolaDermoide?: boolean;
    fistolaDermoideDoc?: string;
    dataInsFistolaDermoideDoc?: Date,
    numFemmine?: number;
    numMaschi?: number;
    numDeceduti?: number;
    stalloneInseminaArtificale?: boolean;
    stalloneMontaInItalia?: boolean;
    stato?: string;
    inviato?: boolean;
    recapitoUgualeResidenza?: boolean;
    emailPropStallone?: string;
    cellularePropStallone?: string;
    mode: ModuloAMode;
    recapitoIspezione?: RecapitoIspezione
    hasAnomalie?: boolean;
    idoneitaFattriceFiles?: File[];
    fistolaDermoideFiles?: File[];
    stalloneEsteroFiles?: File[];
}

export enum ModuloAMode {
    None = 0,
    Insert = 1,
    UpdateModuloA = 2,
    UpdateFattrice = 3,
    UpdateStallone = 4,
    UpdateCucciolata = 5,
    UpdateDocs = 6,
    UpdateDate = 7,
    UpdateRecapito = 8, //puo' funzionare anche da insert
}

export interface RecapitoIspezione {
    id?: number;
    moduloAId: number;
    nome: string;
    email: string;
    cellulare: string;
    indirizzo: string
    civico: string;
    cap: string;
    provincia: string;
    localita: string;
    presso: string;
    delegazioneId: string;
    nomeDelegazione: string 
}

export interface ModuloB {
    id?: number;
    enciDenunciaId?: string;
    stato?: string;
    inviato?: boolean;
    mode: ModuloBMode;
    cuccioli?: Cucciolo[]
}

export interface Cucciolo {
    id?: number;
    moduloBId: number;
    microchip?: string;
    nome?: string;
    enciRazzaId?: string;
    datanascita?: string;
    varieta?: string; //e' un id
    mantello?: string; //e' un id
    tatuaggio?: string;
    sesso?: string;
    descrizione?: string;
    veterinarioDoc?: string;
    dataVeterinarioDoc?: Date;
    dataInsVeterinarioDoc?: Date;
    fistolaDermoideDoc?: string;
    dataInsFistolaDermoideDoc?: Date;
//0 o null – Invalido/non ceduto
//1 – proprietario italiano
//2 – proprietario straniero
//3 – allevamento
    tipoProprietario?: string;
    ceduto?: boolean;
    proprietarioId?: string;
    proprietarioCodiceFiscale?: string;
    proprietarioNome?: string;
    proprietarioCognome?: string;
    proprietarioSesso?: string;
    proprietarioIndirizzo?: string;
    proprietarioCivico?: string;
    proprietarioCap?: string;
    proprietarioProvincia?: string;
    proprietarioLocalita?: string;
    proprietarioEmail?: string;
    proprietarioTelefono?: string;
    proprietarioFax?: string;
    proprietarioCellulare?: string;
    proprietarioNazione?: string;
    proprietarioDelegazioneId?: string;
    stampaPedigreeCartaceo?: boolean;
    deleted?: boolean;
    fistolaDermoide?: boolean;
    inAnagrafeCanina?: boolean;
    dataNascitaAnagrafeCanina?: string;
    peloLungo?: boolean;
    ridge?: string;
    veterinarioFile?: File[];
    fistolaFile?: File[];
}

export enum ModuloBMode {
    None = 0,
    Insert = 1,
    UpdateModuloB = 2,
    UpdateCuccioli = 3
}

export enum ModuloAUpdateDocMode {
    None = 0,
    UpdateFattriceDoc = 1,
    UpdateFistolaDermoideDoc = 2,
    UpdateStalloneEsteroDoc = 3
}

export interface AnomaliaModA {
    codice: string;
    sezioneCoinvolta: number,
    caneId: string,
    messaggio: string
};

export interface Nazione {
    id: number;
    nome: string;
    siglaIso3166Alpha2: string;
    enciCodNazione: string;
    isDisabled: boolean;
}

export interface DocumentiFromScp {
    idfile : string;
    filename : string;
    contenttype : string;
    filelength : string;
    idEntita : number;
}