import { Routes, RouterModule } from '@angular/router';
import { AuthCallbackComponent } from './authorization/auth-callback.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './authorization/unauthorized.component';
import { HomeComponent } from './modules/home/home.component';
import { FastApproveComponent } from './components/fast-approve/fast-approve.component';
import { FastApproveSuccessComponent } from './components/fast-approve-success/fast-approve-success.component';
import { FastApproveRefusedComponent } from './components/fast-approve-refused/fast-approve-refused.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'callback', component: AuthCallbackComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'fast-approve/:idAllevatore/:idDenuncia/success', component: FastApproveSuccessComponent },
  { path: 'fast-approve/:idAllevatore/:idDenuncia/error', component: FastApproveRefusedComponent },
  { path: 'fast-approve/:idAllevatore/:idDenuncia', component: FastApproveComponent },
  {
    path: 'payment',
    loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'home',
    canActivate: [AutoLoginPartialRoutesGuard],
    component: HomeComponent
  },
  {
    path: 'genetic', canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/genetic/genetic.module').then(m => m.GeneticModule)
  },
  {
    path: 'payment', canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'help', canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
  },
  {
    path: 'handover', canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/handover/handover.module').then(m => m.HandoverModule)
  },
  { path: '**', redirectTo: 'home' }
];

export const AppRoutingModule = RouterModule.forRoot(routes, {
  paramsInheritanceStrategy: 'always',
  enableTracing: false
});
