<div [ngSwitch]="name" class="h-full flex align-items-center">
  <svg *ngSwitchCase="'folder'" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
    <g id="Raggruppa_744" data-name="Raggruppa 744" transform="translate(568 330)">
      <g id="Raggruppa_743" data-name="Raggruppa 743" transform="translate(-568 -330)">
        <rect id="Rettangolo_7" data-name="Rettangolo 7" width="44" height="44" fill="currentColor" opacity="0" />
      </g>
      <path id="folder_open_FILL0_wght400_GRAD0_opsz48"
        d="M81.256-786.6a1.2,1.2,0,0,1-.869-.387A1.2,1.2,0,0,1,80-787.86v-10.884a1.2,1.2,0,0,1,.387-.869,1.2,1.2,0,0,1,.869-.387h5.881l1.256,1.256h7.1a1.2,1.2,0,0,1,.869.387,1.2,1.2,0,0,1,.387.869h-8.9l-1.256-1.256H81.256v10.884l2.135-8.372H98l-2.24,8.665a1.2,1.2,0,0,1-.46.733,1.483,1.483,0,0,1-.858.23Zm1.319-1.256H94.547l1.758-7.116H84.333Zm0,0,1.758-7.116Zm-1.319-9.628v0Z"
        transform="translate(-635 485)" fill="currentColor" />
    </g>
  </svg>

  <svg *ngSwitchCase="'file'" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
    <g id="Raggruppa_745" data-name="Raggruppa 745" transform="translate(640 330)">
      <g id="Raggruppa_742" data-name="Raggruppa 742" transform="translate(-778 -561)">
        <g id="Raggruppa_295" data-name="Raggruppa 295" transform="translate(138 231)">
          <rect id="Rettangolo_7" data-name="Rettangolo 7" width="44" height="44" fill="#898989" opacity="0" />
        </g>
      </g>
      <path id="description_FILL0_wght400_GRAD0_opsz48"
        d="M163.577-865.825h7.245v-1.35h-7.245Zm0-3.825h7.245V-871h-7.245ZM161.35-862a1.3,1.3,0,0,1-.945-.405,1.3,1.3,0,0,1-.4-.945v-15.3a1.3,1.3,0,0,1,.4-.945,1.3,1.3,0,0,1,.945-.4h8.122l4.928,4.927v11.722a1.3,1.3,0,0,1-.4.945,1.3,1.3,0,0,1-.945.405Zm7.448-12.465v-4.185H161.35v15.3h11.7v-11.115Zm-7.448-4.185v0Z"
        transform="translate(-785 563)" fill="currentColor" />
    </g>
  </svg>

  <svg *ngSwitchCase="'folder_open'" xmlns="http://www.w3.org/2000/svg" width="18" height="13.395"
    viewBox="0 0 18 13.395">
    <path id="folder_open_FILL0_wght400_GRAD-25_opsz20"
      d="M107.458-744.6a1.326,1.326,0,0,1-1.067-.462A1.721,1.721,0,0,1,106-746.22v-10.173a1.706,1.706,0,0,1,.392-1.15,1.331,1.331,0,0,1,1.067-.458h4.7l1.981,2.192h6.7a1.234,1.234,0,0,1,1.018.47,2.114,2.114,0,0,1,.441,1.144h-8.75l-1.981-2.192h-4.114v9.974l1.654-6.168H124l-1.839,6.794a2.709,2.709,0,0,1-.435.855.958.958,0,0,1-.794.325Zm1.458-1.614h11.819l1.328-4.746H110.2Zm0,0,1.284-4.746Zm-1.458-7.975v0Z"
      transform="translate(-106 758)" fill="currentColor" />
  </svg>

  <svg *ngSwitchCase="'check'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path id="check_circle_FILL0_wght400_GRAD-25_opsz20"
      d="M113.8-841.655l5.559-5.535-1.155-1.155-4.4,4.38-2-1.973-1.155,1.155ZM115-836a8.754,8.754,0,0,1-3.491-.7,9.068,9.068,0,0,1-2.871-1.937,9.118,9.118,0,0,1-1.934-2.871A8.741,8.741,0,0,1,106-845a8.728,8.728,0,0,1,.7-3.5,9.106,9.106,0,0,1,1.937-2.859,9.118,9.118,0,0,1,2.871-1.934A8.741,8.741,0,0,1,115-854a8.729,8.729,0,0,1,3.5.7,9.106,9.106,0,0,1,2.859,1.937A9.156,9.156,0,0,1,123.3-848.5a8.715,8.715,0,0,1,.7,3.5,8.754,8.754,0,0,1-.7,3.491,9.068,9.068,0,0,1-1.937,2.871A9.156,9.156,0,0,1,118.5-836.7,8.714,8.714,0,0,1,115-836Zm0-1.612a7.121,7.121,0,0,0,5.228-2.16,7.121,7.121,0,0,0,2.16-5.228,7.121,7.121,0,0,0-2.16-5.228,7.121,7.121,0,0,0-5.228-2.16,7.121,7.121,0,0,0-5.228,2.16,7.121,7.121,0,0,0-2.16,5.228,7.121,7.121,0,0,0,2.16,5.228A7.121,7.121,0,0,0,115-837.612ZM115-845Z"
      transform="translate(-106 854)" fill="currentColor" />
  </svg>

  <svg *ngSwitchCase="'file_arrow_right'" xmlns="http://www.w3.org/2000/svg" width="15.428" height="18"
    viewBox="0 0 15.428 18">
    <g id="Raggruppa_861" data-name="Raggruppa 861" transform="translate(-65 -269)">
      <path id="description_FILL0_wght400_GRAD-25_opsz20"
        d="M202.605-836a1.541,1.541,0,0,1-1.135-.474,1.559,1.559,0,0,1-.47-1.139v-14.775a1.553,1.553,0,0,1,.474-1.139,1.553,1.553,0,0,1,1.139-.474h7.412l4.4,4.392v12a1.552,1.552,0,0,1-.474,1.139,1.554,1.554,0,0,1-1.139.474Zm6.336-12.513v-3.874h-6.329v14.775h10.2v-10.9Zm-6.329-3.874v0Z"
        transform="translate(-134 1123)" fill="currentColor" />
      <g id="Raggruppa_855" data-name="Raggruppa 855" transform="translate(65 277.054)">
        <path id="Tracciato_183" data-name="Tracciato 183" d="M-224.442,534.826h10.8"
          transform="translate(224.442 -531.855)" fill="none" stroke="currentColor" stroke-width="1.5" />
        <path id="Tracciato_184" data-name="Tracciato 184" d="M-206.37,520.683l2.971,2.971-2.971,2.971"
          transform="translate(214.199 -520.683)" fill="none" stroke="currentColor" stroke-width="1.5" />
      </g>
    </g>
  </svg>

  <svg *ngSwitchCase="'help'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 0C4.04 0 0 4.04 0 9C0 13.96 4.04 18 9 18C13.96 18 18 13.96 18 9C18 4.04 13.96 0 9 0ZM9 16.5C4.86 16.5 1.5 13.14 1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9C16.5 13.14 13.14 16.5 9 16.5ZM11.3 4.7C11.91 5.31 12.25 6.13 12.25 7C12.25 7.87 11.91 8.68 11.3 9.3C10.87 9.73 10.33 10.03 9.75 10.16V10.5C9.75 10.91 9.41 11.25 9 11.25C8.59 11.25 8.25 10.91 8.25 10.5V9.5C8.25 9.09 8.59 8.75 9 8.75C9.47 8.75 9.91 8.57 10.24 8.24C10.57 7.91 10.75 7.47 10.75 7C10.75 6.53 10.57 6.09 10.24 5.76C9.58 5.1 8.43 5.1 7.77 5.76C7.44 6.09 7.26 6.53 7.26 7C7.26 7.41 6.92 7.75 6.51 7.75C6.1 7.75 5.76 7.41 5.76 7C5.76 6.13 6.1 5.32 6.71 4.7C7.94 3.47 10.08 3.47 11.31 4.7H11.3ZM10 13.25C10 13.8 9.55 14.25 9 14.25C8.45 14.25 8 13.8 8 13.25C8 12.7 8.45 12.25 9 12.25C9.55 12.25 10 12.7 10 13.25Z"
      fill="currentColor" />
  </svg>

  <svg *ngSwitchCase="'plus'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_2)">
      <path d="M8.3571 9.6429H0V8.3571H8.3571V0H9.6429V8.3571H18V9.6429H9.6429V18H8.3571V9.6429Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="18" height="18" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</div>