import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ModuloAEnci,
  GetAnagraficaResponse,
  GetModuloAResponse,
  GetRazzasResponse,
  GetStalloneFattriceResponse,
  StalloneFattrice,
  Allevatore,
  PutModuloA,
  CuccioliModuloB,
  CucciolataResponse,
  ValidateStalloneFattrice,
  InsertUpdateModuloAResponse,
  ResponseCompatibilitaVarietaMantello,
  CuccioliModelloBToSend,
  RecuperoDatiDaCodiceFiscale,
  ResponseInfoFromChip,
  RecuperoDelegazioni,
  Listino,
  ResponseStatoModulo,
  Ricevuta,
  DenunciaStalloneConfirmed
} from '../modules/shared/interfaces/enci';
import { BehaviorSubject, Observable, ReplaySubject, forkJoin, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators'
import { buildServiceUrl } from './moduloab.service';
import { ModuloA } from '../modules/shared/interfaces/scp';
import { adjustFieldsDenunceFromEnci, adjustFieldsDenunciaFromEnci, convertModuloAToDichiarazione } from '../modules/shared/shared.functions';
import { environment } from '../../environments/environment';
import { TranslateService } from './translate.service';
import uniqBy from 'lodash-es/uniqBy';

@Injectable({
  providedIn: 'root'
})
export class EnciApiService {
  T$: Observable<any>;
  translateItems: any;

  private _currentUserEnci: BehaviorSubject<Allevatore> = new BehaviorSubject<Allevatore>(undefined);

  //N.B. puo' contenere sia un modulo A proveniente da ENCI sia un modulo A draft proveniente da scp
  private _currentModuloA$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  private _errorFromService: ReplaySubject<any> = new ReplaySubject<any>(1);

  public getObsErrorFromService(): Observable<any> {
    return this._errorFromService.asObservable();
  }
  getCachedModuloA(): Observable<any> {
    return this._currentModuloA$.asObservable().pipe(filter(v => !!v));
  }
  getStatiModelloA(): Observable<ResponseStatoModulo> {
    return this.httpClient.get<ResponseStatoModulo>(`${environment.baseUrlEnci}/modelloA/stato`);
  }
  getStatiModelloB(): Observable<ResponseStatoModulo> {
    return this.httpClient.get<ResponseStatoModulo>(`${environment.baseUrlEnci}/cane/stato`);
  }
  getCurrentModuloA(id: number | string, draft: boolean, hardRefresh?: boolean, idAllevatoreExtra?: string): Observable<any> {

    let needToRefreshCache = hardRefresh || this._currentModuloA$.value == undefined;

    if (!needToRefreshCache) {
      if (draft) {
        needToRefreshCache = this._currentModuloA$.value.idDraft != id;
      } else {
        needToRefreshCache = this._currentModuloA$.value.id != id
      }
    }
    if (needToRefreshCache) {
      this._currentModuloA$.next(undefined);
      if (draft) {
        let vUrl = `${buildServiceUrl('moduloab', 'moduloa/' + id)}`;
        this.populateStalloneFattriceDraft(vUrl).subscribe((moduloa) => {
          this._currentModuloA$.next(moduloa);
        });
      } else {
        let idAllevatore = idAllevatoreExtra ? idAllevatoreExtra : this._currentUserEnci.value.anagrafica.id;
        this.getModuloADetail(idAllevatore, id.toString()).subscribe({
          next: (res) => {
            let denuncia = res.result[0];
            if(!denuncia) {
              this._errorFromService.next(this.translateService.translate(this.translateItems,'err.recuperoinfomoda')); // Errore nel recupero delle informazioni del modello A. Riprovare a caricare la pagina. Se persiste contattare ENCI
              return;
            }
            denuncia = adjustFieldsDenunciaFromEnci(denuncia);
            if (res.anomalie?.length) {
              denuncia.anomalie = res.anomalie;
            }
            this._currentModuloA$.next(denuncia);
          },
          error: (_err) => {
            this._errorFromService.next(this.translateService.translate(this.translateItems,'err.recuperoinfomoda')); // Errore nel recupero delle informazioni del modello A. Riprovare a caricare la pagina. Se persiste contattare ENCI
          }

        });
      }
    }
    return this._currentModuloA$.asObservable().pipe(filter(v => !!v));
  }
  setCurrentModuloA(moduloA: any): void {
    this._currentModuloA$.next(moduloA);
  }
  getAllevatore(codFisc?: string): Observable<Allevatore> {

    if (codFisc && (!this._currentUserEnci.value || (codFisc && this._currentUserEnci.value.anagrafica.codicefiscale != codFisc))) {
      this._currentUserEnci.next(undefined);
      this.getRegistries(codFisc).subscribe({
        next: (res) => {
          if (res.result?.length) {
            this._currentUserEnci.next(res.result[0]);
          } else if (res.message) {
            this._errorFromService.next(res.message);
          } else if (res.anomalie?.length) {
            res.anomalie.forEach(anomalia => {
              this._errorFromService.next(anomalia.descrizione);
            });
          }
        },
        error: (_err) => {
          this._errorFromService.next(this.translateService.translate(this.translateItems,'err.recuperoinfoallevatore')); //Errore nel recupero delle informazioni dell\'allevatore. Riprovare a caricare la pagina. Se persiste contattare ENCI'
        }

      });
    }
    return this._currentUserEnci.asObservable().pipe(filter(v => !!v));
  }

  constructor(private httpClient: HttpClient, private translateService: TranslateService) {

  }

  initializeTranslation(items: any) {
    // this.T$ = this.translateService.translatedItems$(['err.','mes']).pipe(
    //   tap(items => {
    //     this.translateItems = items;
    //   })
    // );
    this.translateItems = items;
  }

  getRegistries(codiceFiscale: string): Observable<GetAnagraficaResponse> {
    return this.httpClient.get<GetAnagraficaResponse>(`${environment.baseUrlEnci}/anagrafica/${codiceFiscale}`);
  }
  getModuloAList(idAllevatore: string): Observable<ModuloAEnci[]> {
    return this.httpClient.get<GetModuloAResponse>(`${environment.baseUrlEnci}/modelloA/${idAllevatore}`).pipe(map((response) => {

      return uniqBy(response.result, 'iddenuncia');
    }));
  }
  private getModuloADetail(idAllevatore: string, iddenuncia: string): Observable<GetModuloAResponse> {
    return this.httpClient.get<GetModuloAResponse>(`${environment.baseUrlEnci}/modelloA/${idAllevatore}/${iddenuncia}`);
  }
  insertUpdateModuloADetail(data: PutModuloA): Observable<InsertUpdateModuloAResponse> {
    let idAllevatore = this._currentUserEnci.value.anagrafica.id;
    return this.httpClient.put<InsertUpdateModuloAResponse>(`${environment.baseUrlEnci}/modelloA/manager/${idAllevatore}`, data);
  }
  getAllDenunceDaConfermare(idAllevatore: string): Observable<ModuloAEnci[]> {
    return this.httpClient.get<GetModuloAResponse>(`${environment.baseUrlEnci}/modelloA/daconfermare/${idAllevatore}`).pipe(map((response) => {
      if (response.status != 0) {
        if (response.status == 1 && response.message == this.translateService.translate(this.translateItems,'err.norecordtrovato')) {
          let denunce: ModuloAEnci[] = [];
          return denunce;
        }
        if (response.anomalie?.length) {
          response.anomalie.forEach((anomalia) => {
            this._errorFromService.next(anomalia.descrizione);
          });
        } else {
          this._errorFromService.next(response.message);
        }
        let denunce: ModuloAEnci[] = [];
        return denunce;
      } else {
        return adjustFieldsDenunceFromEnci(uniqBy(response.result, 'iddenuncia'));
      }
    }));
  }
  getAllDenunceConfermate(idAllevatore: string): Observable<ModuloAEnci[]> {
    return this.httpClient.get<GetModuloAResponse>(`${environment.baseUrlEnci}/modelloA/confermate/${idAllevatore}`).pipe(map((response) => {

      if (response.status != 0) {
        if (response.status == 1 && response.message == this.translateService.translate(this.translateItems,'err.norecordtrovato')) {
          let denunce: ModuloAEnci[] = [];
          return denunce;
        }
        if (response.anomalie?.length) {
          response.anomalie.forEach((anomalia) => {
            this._errorFromService.next(anomalia.descrizione);
          });
        } else {
          this._errorFromService.next(response.message);
        }
        let denunce: ModuloAEnci[] = [];
        return denunce;
      } else {
        return adjustFieldsDenunceFromEnci(uniqBy(response.result, 'iddenuncia'));
      }
    }));
  }
  getRazzas(idProprietario: string): Observable<GetRazzasResponse> {

    return this.httpClient.get<GetRazzasResponse>(`${environment.baseUrlEnci}/razza/${idProprietario}`)
  }
  getFemaleDogs(
    idrazza: string,
    datanascitamodea: string,
    datamontamodea: string,
    chip: string,
    codice: string,
    idproprie: string): Observable<GetStalloneFattriceResponse> {

    let params = new HttpParams()
      .set('datanascitamodea', datanascitamodea)
      .set('datamontamodea', datamontamodea)
      .set('idproprie', idproprie);

    if (idrazza) {
      params = params.set('idrazza', idrazza);
    }

    if (chip) {
      params = params.set('chip', chip);
    }

    if (codice) {
      params = params.set('codice', codice);
    }
    return this.httpClient.get<GetStalloneFattriceResponse>(`${environment.baseUrlEnci}/cane/fattrice`, {
      params
    })
  }
  getMaleDogs(idrazza: string, datanascitamodea: string, datamontamodea: string, codicefiscaleproprie: string,
    chip: string, codice: string, nome: string): Observable<GetStalloneFattriceResponse> {

    let params = new HttpParams()
      .set('idrazza', idrazza)
      .set('datanascitamodea', datanascitamodea)
      .set('datamontamodea', datamontamodea);

    if (codicefiscaleproprie) {
      params = params.set('codicefiscaleproprie', codicefiscaleproprie);
    }

    if (chip) {
      params = params.set('chip', chip);
    }

    if (codice) {
      params = params.set('codice', codice);
    }

    if (nome) {
      params = params.set('nome', nome);
    }

    return this.httpClient.get<GetStalloneFattriceResponse>(`${environment.baseUrlEnci}/cane/stallone`, {
      params
    });
  }
  getDogsModuloB(idModuloA: string): Observable<CuccioliModuloB[]> {
    return this.httpClient.get<CucciolataResponse>(`${environment.baseUrlEnci}/cane/cuccioli/${idModuloA}`).pipe(map((res) => {
      let cuccioli: CuccioliModuloB[] = [];

      if (res.result?.length) {
        return res.result;
      } else if (res.message) {
        this._errorFromService.next(res.message);
      } else if (res.anomalie?.length) {
        res.anomalie.forEach(anomalia => {
          this._errorFromService.next(anomalia.descrizione);
        });
      }

      return cuccioli;

    }));
  }
  getListinoModuloB(idModuloA: string): Observable<{ listini: Listino[], ricevuta: Ricevuta }> {
    return this.httpClient.get<CucciolataResponse>(`${environment.baseUrlEnci}/cane/cuccioli/${idModuloA}`).pipe(map((res) => {
      let listini: Listino[] = [];


      if (res.status == 0 && res.result?.length) {
        return { listini: res.result[0].listino, ricevuta: res.result[0].ricevuta };
      }

      return { listini, ricevuta: undefined };

    }));
  }
  getDogsMassive(ids: string[]): Observable<StalloneFattrice[]> {
    if (!ids?.length) {
      let arrayEmpty: StalloneFattrice[] = [];
      return of(arrayEmpty);
    }

    return this.httpClient.post<GetStalloneFattriceResponse>(`${environment.baseUrlEnci}/cane/ids`, ids.join(','))
      .pipe(map((res) => {
        return res.result;
      }))
  }
  getVarietaCompatibilita(idDenuncia: string, dataDenunciaYYYYMMDD: string) {
    return this.httpClient.get<ResponseCompatibilitaVarietaMantello>(`${environment.baseUrlEnci}/razza/compatibilita/${idDenuncia}/${dataDenunciaYYYYMMDD}`);
  }
  validateFattrice(idFattrice: string, datamonta: string, datanascita: string): Observable<ValidateStalloneFattrice> {
    const data = {
      "datanascitamodelloa": datanascita,
      "datamontamodelloa": datamonta
    };

    return this.httpClient.post<ValidateStalloneFattrice>(`${environment.baseUrlEnci}/cane/fattrice/validate/${idFattrice}`, data);
  }
  validateStallone(idStallone: string, datamonta: string, datanascita: string): Observable<ValidateStalloneFattrice> {
    const data = {
      "datanascitamodelloa": datanascita,
      "datamontamodelloa": datamonta
    };

    return this.httpClient.post<ValidateStalloneFattrice>(`${environment.baseUrlEnci}/cane/stallone/validate/${idStallone}`, data);
  }
  ottieniDatiDaCodiceFiscale(codFisc: string): Observable<RecuperoDatiDaCodiceFiscale> {
    return this.httpClient.get<RecuperoDatiDaCodiceFiscale>(`${environment.baseUrlEnci}/anagrafica/proprietario/${codFisc}`);
  }
  ottieniAllevamentiDaCodiceFiscale(codFisc: string): Observable<RecuperoDatiDaCodiceFiscale> {
    return this.httpClient.get<RecuperoDatiDaCodiceFiscale>(`${environment.baseUrlEnci}/anagrafica/allevamento/${codFisc}`);
  }
  // validaMicrochip(microchip: string): Observable<ResponseCompatibilitaMicrochip> {
  //   return this.httpClient.get<ResponseCompatibilitaMicrochip>(`${this.baseUrl}/cane/anagrafeCanina/${microchip}`);
  // }
  getInfoFromMicrochip(microchip: string): Observable<ResponseInfoFromChip> {
    return this.httpClient.get<ResponseInfoFromChip>(`${environment.baseUrlEnci}/cane/anagrafeCanina/${microchip}`);
  }
  registraCuccioliModB(idAllevatore: string, iddenuncia: string, cuccioli: CuccioliModelloBToSend[]): Observable<GetModuloAResponse> {
    return this.httpClient.put<GetModuloAResponse>(`${environment.baseUrlEnci}/cane/manager/${idAllevatore}/${iddenuncia}`, cuccioli);
  }
  convalidaDenunciaPropStallone(idAllevatore: string, idDenuncia: string): Observable<DenunciaStalloneConfirmed> {
    return this.httpClient.put<DenunciaStalloneConfirmed>(`${environment.baseUrlEnci}/modelloA/conferma/${idAllevatore}/${idDenuncia}`, undefined);
  }
  rifiutaDenunciaPropStallone(idAllevatore: string, idDenuncia: string, motivazioneRifiuto: string): Observable<DenunciaStalloneConfirmed> {

    var formData = new FormData();
    formData.append('notaRifiuto', motivazioneRifiuto)
    return this.httpClient.put<DenunciaStalloneConfirmed>(`${environment.baseUrlEnci}/modelloA/rifiuto/${idAllevatore}/${idDenuncia}`, formData);
  }
  deleteModA(idAllevatore: string, idDenuncia: string): Observable<any> {
    return this.httpClient.delete(`${environment.baseUrlEnci}/modelloA/${idAllevatore}/${idDenuncia}`);
  }
  calculateDelegazione(comune: string, provincia: string, cap: string): Observable<RecuperoDelegazioni> {
    return this.httpClient.get<RecuperoDelegazioni>(`${environment.baseUrlEnci}/modelloA/delegazione/${comune}/${provincia}/${cap}`);
  }
  retrieveDelegazione(idDelegazione: string): Observable<RecuperoDelegazioni> {
    return this.httpClient.get<RecuperoDelegazioni>(`${environment.baseUrlEnci}/anagrafica/delegazione/${idDelegazione}`);
  }
  getRicevuta(nr: string, anno: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), responseType: 'blob' as 'json'
    };
    return this.httpClient.get<any>(`${environment.baseUrlEnci}/ricevuta/${nr}/${anno}`, options);
  }
  getSchedaInformativaCane(idCane: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), responseType: 'blob' as 'json'
    };
    return this.httpClient.get<any>(`${environment.baseUrlEnci}/cane/pedigree/${idCane}`, options);
  }
  private populateStalloneFattriceDraft(vUrl): Observable<ModuloAEnci> {
    return this.httpClient.get<ModuloA>(vUrl).pipe(mergeMap((draft) => {
      if (draft) {
        let idsCani: string[] = [];

        if (draft.enciFattriceId) {
          idsCani.push(draft.enciFattriceId);
        }

        if (draft.enciStalloneId) {
          idsCani.push(draft.enciStalloneId);
        }
        return forkJoin([of(draft), this.getDogsMassive(idsCani)]);
      } else { //non ci sono draft, non chiamo inutilmente i servizi enci
        let arrayEmptyCani: StalloneFattrice[] = [];
        return forkJoin([of(undefined), of(arrayEmptyCani)]);
      }
    }), map(([draftModA, dogs]) => {

      let draftBreeder: ModuloAEnci;
      if (draftModA) {
        let draft = draftModA as ModuloA;

        let fattrice: StalloneFattrice = undefined
        if (draft.enciFattriceId) {
          fattrice = dogs.find(d => d.id.toString() == draft.enciFattriceId);
        }

        let stallone: StalloneFattrice = undefined
        if (draft.enciStalloneId) {
          stallone = dogs.find(d => d.id.toString() == draft.enciStalloneId);
        }

        //assegnare i vari campi per matchare con breeder
        draftBreeder = convertModuloAToDichiarazione(draft, stallone, fattrice, this._currentUserEnci.value);

      }
      return draftBreeder;

    }));
  }
}
