import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject, mergeMap, tap } from 'rxjs';
import { BaseComponent } from 'src/app/modules/shared/components/base/base.component';
import { TranslateService } from 'src/app/services/translate.service';
import { getLanguageFromBrowser } from '../../modules/shared/shared.functions';
@Component({
  selector: 'app-fast-approve-success',
  templateUrl: './fast-approve-success.component.html',
  styleUrls: ['./fast-approve-success.component.scss']
})
export class FastApproveSuccessComponent extends BaseComponent implements OnInit, OnDestroy {
  T$: Observable<any>;
  translateItems: any;
  langSubject$: ReplaySubject<string> = new ReplaySubject<string>(1);
  
  constructor(private translateService: TranslateService){
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.T$ = this.langSubject$.asObservable().pipe(mergeMap((lang) => {
      return this.translateService.translatedItems$(['err.','mes.'], lang)} ), 
      tap(items => {
        this.translateItems = items;
      })
    );
		let lang = getLanguageFromBrowser();
    this.langSubject$.next(lang);
  }
}
