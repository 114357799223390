import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../modules/shared/shared.export-constants';
import { Delegazione, ModuloAEnci, StalloneFattrice } from '../../modules/shared/interfaces/enci';
import { FormBuilder, FormControl } from '@angular/forms';
import { ModuloABService } from '../../services/moduloab.service';
import { mergeMap, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { createMessageToastPrimeNg, getLanguageFromBrowser, isNull, recapitoIsEmpty } from '../../modules/shared/shared.functions';
import { TranslateService } from 'src/app/services/translate.service';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'fast-approve',
  templateUrl: 'fast-approve.component.html',
  styleUrls: ['./fast-approve.component.scss']
})

export class FastApproveComponent implements OnInit {
  T$: Observable<any>;
  translateItems: any;

  idAllevatore: string;
  idDenuncia: string;

  Constants = Constants;
  loading: boolean = false;

  id: string;
  fattrice: StalloneFattrice;
  stallone: StalloneFattrice;

  errorRetrieveModA: boolean = false;
  visibleDialogCode: boolean = true;
  currentCode: string;

  showCodiceNonValido: boolean = false;
  checkingCode: boolean = false;

  askingNuovoCodice: boolean = false;
  showCodiceReinviato: boolean = false;
  cannotReSendCodeReason: string;

  confirmingDenuncia: boolean = false;

  langSubject$: ReplaySubject<string> = new ReplaySubject<string>(1);

  denuncia: ModuloAEnci;
  delegazione: Delegazione;

  basicForm = this.formBuilder.group({
    taxId: new FormControl({ value: '', disabled: true }),
    firstName: new FormControl({ value: '', disabled: true }),
    // memberType: new FormControl({ value: '', disabled: true }),
    // year: new FormControl({ value: '', disabled: true }),
    // cardNumber: new FormControl({ value: '', disabled: true }),
    affix: new FormControl({ value: '', disabled: true }),
    email: new FormControl({ value: '', disabled: true }),
    phone: new FormControl({ value: '', disabled: true }),
    mobilePhone: new FormControl({ value: '', disabled: true }),

    taxIdPropStallone: new FormControl({ value: '', disabled: true }),
    firstNamePropStallone: new FormControl({ value: '', disabled: true }),
    // memberTypePropStallone: new FormControl({ value: '', disabled: true }),
    // yearPropStallone: new FormControl({ value: '', disabled: true }),
    // cardNumberPropStallone: new FormControl({ value: '', disabled: true }),
    affixPropStallone: new FormControl({ value: '', disabled: true }),
    emailPropStallone: new FormControl({ value: '', disabled: true }),
    phonePropStallone: new FormControl({ value: '', disabled: true }),
    mobilePhonePropStallone: new FormControl({ value: '', disabled: true }),

    dataDiNascita: new FormControl({ value: '', disabled: true }),
    dataDiMonta: new FormControl({ value: '', disabled: true }),
    female: new FormControl({ value: 0, disabled: true }),
    male: new FormControl({ value: 0, disabled: true }),
    deceased: new FormControl({ value: 0, disabled: true }),
    total: new FormControl({ value: 0, disabled: true })
  });

  contactForm = this.formBuilder.group({
    street: new FormControl({ value: '', disabled: true }),
    postalCode: new FormControl({ value: '', disabled: true }),
    province: new FormControl({ value: '', disabled: true }),
    city: new FormControl({ value: '', disabled: true }),
    email: new FormControl({ value: '', disabled: true }),
    phone: new FormControl({ value: '', disabled: true }),
    mobilePhone: new FormControl({ value: '', disabled: true })
  });

  sameAddressForIspezione: boolean = false;

  contactFormOptional = this.formBuilder.group({
    presso: new FormControl({ value: '', disabled: true }),
    street: new FormControl({ value: '', disabled: true }),
    postalCode: new FormControl({ value: '', disabled: true }),
    province: new FormControl({ value: '', disabled: true }),
    city: new FormControl({ value: '', disabled: true }),
    email: new FormControl({ value: '', disabled: true }),
    mobilePhone: new FormControl({ value: '', disabled: true })
  });

  motivazioneRifiuto: string;
  showDialogRifiuto: boolean = false;
  showReadsonNotValid: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private moduloAbService: ModuloABService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private router: Router,
    private translateService: TranslateService) {
  }

  ngOnInit() {

    this.idAllevatore = this.activatedRoute.snapshot.params.idAllevatore;
    this.idDenuncia = this.activatedRoute.snapshot.params.idDenuncia;

    this.visibleDialogCode = false;
    this.recuperaDenuncia();

    this.T$ = this.langSubject$.asObservable().pipe(mergeMap((lang) => {
      return this.translateService.translatedItems$(['modb.', 'moda.', 'err.', 'mes.', 'cane.', 'tiposocio.'], lang)
    }),
      tap(items => {
        this.translateItems = items;
      })
    );
    let lang = getLanguageFromBrowser();
    this.langSubject$.next(lang);

  }

  tryToValidateCode() {
    this.checkingCode = true;
    this.moduloAbService.checkCode(this.currentCode, this.idAllevatore, this.idDenuncia).subscribe({
      next: () => {
        this.checkingCode = false;
        this.recuperaDenuncia();
      },
      error: (err) => {
        if (err.status == 404) {
          this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
            this.translateService.translate(this.translateItems, 'mes.denuncianontrovata.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true)); //'Denuncia o allevatore non trovati'
        } else if (err.error) {

          this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
            err.error, this.translateService.translate(this.translateItems, 'err.errore'), "error", true));
        } else {
          this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
            this.translateService.translate(this.translateItems, 'mes.pinnonvalido.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true)); //"PIN non valido o scaduto"
        }
        this.checkingCode = false;
      }
    });
  }

  recuperaDenuncia() {
    this.loading = true;
    this.errorRetrieveModA = false;
    this.moduloAbService.getDenunciaDaConfermarePropStallone(this.idAllevatore, this.idDenuncia).subscribe({
      next: (denuncia) => {
        if (!denuncia) {
          this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
            this.translateService.translate(this.translateItems, 'mes.denunciagiaapprovata.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true)); //"Denuncia non trovata o già approvata"
          this.loading = false;
          return;
        }
        this.denuncia = denuncia;

        this.contactForm.controls.city.setValue(denuncia.proprieallanascitafattrice.localitaDomicilio);
        this.contactForm.controls.email.setValue(isNull(denuncia.proprieallanascitafattrice.email)
          ? '' : denuncia.proprieallanascitafattrice.email);
  
        this.contactForm.controls.mobilePhone.setValue(isNull(denuncia.proprieallanascitafattrice.cellulare)
          ? '' : denuncia.proprieallanascitafattrice.cellulare);
        this.contactForm.controls.phone.setValue(isNull(denuncia.proprieallanascitafattrice.telefono)
          ? '' : denuncia.proprieallanascitafattrice.telefono);
        this.contactForm.controls.postalCode.setValue(denuncia.proprieallanascitafattrice.capDomicilio);
        this.contactForm.controls.province.setValue(denuncia.proprieallanascitafattrice.provinciaDomicilio);
        this.contactForm.controls.street.setValue(denuncia.proprieallanascitafattrice.indirizzoDomicilio);

        this.sameAddressForIspezione = this.denuncia.recapitoUgualeResidenza;
        if (this.denuncia.controlloCucciolata && !recapitoIsEmpty(this.denuncia.controlloCucciolata)) {
          this.contactFormOptional.controls.presso.setValue(this.denuncia.controlloCucciolata.presso);
          this.contactFormOptional.controls.street.setValue(this.denuncia.controlloCucciolata.indirizzo);
          this.contactFormOptional.controls.postalCode.setValue(this.denuncia.controlloCucciolata.cap);
          this.contactFormOptional.controls.province.setValue(this.denuncia.controlloCucciolata.provincia);
          this.contactFormOptional.controls.city.setValue(this.denuncia.controlloCucciolata.localita);
          this.contactFormOptional.controls.email.setValue(this.denuncia.controlloCucciolata.email);
          this.contactFormOptional.controls.mobilePhone.setValue(this.denuncia.controlloCucciolata.cellulare);
        }

        this.delegazione = {
          id: '',
          cap: '',
          email: '',
          indirizzo: '',
          localita: '',
          idWallet: '',
          provincia: '',
          telefono: '',
          telefono2: '',
          delegazione: denuncia.delegazione
        };
        this.visibleDialogCode = false;
        this.loading = false;
        this.id = denuncia.iddenuncia;
        this.basicForm.controls.dataDiNascita.setValue(denuncia.datanascita);
        this.basicForm.controls.dataDiMonta.setValue(denuncia.datamonta);
        this.basicForm.controls.female.setValue(Number(denuncia.femmine_vivi));
        this.basicForm.controls.male.setValue(Number(denuncia.maschi_vivi));
        this.basicForm.controls.deceased.setValue(Number(denuncia.tot_morti));
        this.basicForm.controls.total.setValue(Number(denuncia.tot_nati));

        if(denuncia.proprieallanascitafattrice && denuncia.ricevuta) {
          this.basicForm.controls.taxId.setValue(denuncia.proprieallanascitafattrice.codicefiscale);
          this.basicForm.controls.firstName.setValue(denuncia.proprieallanascitafattrice.nome);
          this.basicForm.controls.affix.setValue(denuncia.proprieallanascitafattrice.affisso);
          this.basicForm.controls.email.setValue(denuncia.proprieallanascitafattrice.email);
          // this.basicForm.controls.memberType.setValue(this.translateService.translate(this.translateItems, this.labelTipoSocio(denuncia.ricevuta.tiposocio)));
          // this.basicForm.controls.year.setValue(denuncia.proprieallanascitafattrice.annotessera);
          // this.basicForm.controls.cardNumber.setValue(denuncia.proprieallanascitafattrice.nrtessera);
          this.basicForm.controls.phone.setValue(isNull(denuncia.proprieallanascitafattrice.telefono) ? '' : denuncia.proprieallanascitafattrice.telefono);
          this.basicForm.controls.mobilePhone.setValue(isNull(denuncia.proprieallanascitafattrice.cellulare) ? '' : denuncia.proprieallanascitafattrice.cellulare);
        } else {
          this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
            this.translateService.translate(this.translateItems, 'mes.proprietariafattricenodipso.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true));// "Proprietaria fattrice non disponibile"
          this.errorRetrieveModA = true;
        }

        if (denuncia.fattrice) {
          this.fattrice = denuncia.fattrice;

          this.fattrice.nomeproprietario = denuncia.proprieallanascitafattrice.nome;
          this.fattrice.emailproprietario = denuncia.proprieallanascitafattrice.email;
          this.fattrice.telefonobisproprietario = isNull(denuncia.proprieallanascitafattrice.cellulare) ? '' : denuncia.proprieallanascitafattrice.cellulare;
          this.fattrice.telefonoproprietario = isNull(denuncia.proprieallanascitafattrice.telefono) ? '' : denuncia.proprieallanascitafattrice.telefono;
        } else {
          this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
            this.translateService.translate(this.translateItems, 'mes.fattricenodispo.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true)); // "Fattrice non disponibile"
          this.errorRetrieveModA = true;
        }
        if (denuncia.stallone) {
          this.stallone = denuncia.stallone;
        } else {
          this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
            this.translateService.translate(this.translateItems, 'mes.stallonenodispo.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true)); //Stallone non disponibile
          this.errorRetrieveModA = true;
        }
        if (denuncia.proprieallamontastallone) {
          this.basicForm.controls.taxIdPropStallone.setValue(denuncia.proprieallamontastallone.codicefiscale);
          this.basicForm.controls.firstNamePropStallone.setValue(denuncia.proprieallamontastallone.nome);
          this.basicForm.controls.emailPropStallone.setValue(denuncia.proprieallamontastallone.email);
          this.basicForm.controls.phonePropStallone.setValue(isNull(denuncia.proprieallamontastallone.telefono) ? '' : denuncia.proprieallamontastallone.telefono);
          this.basicForm.controls.mobilePhonePropStallone.setValue(isNull(denuncia.proprieallamontastallone.cellulare) ? '' : denuncia.proprieallamontastallone.cellulare);
          this.basicForm.controls.affixPropStallone.setValue(isNull(denuncia.proprieallamontastallone.affisso) ? '' : denuncia.proprieallamontastallone.affisso);
          // this.basicForm.controls.memberTypePropStallone.setValue(this.translateService.translate(this.translateItems, this.labelTipoSocio(denuncia.proprieallamontastallone.tiposocio)));
          // this.basicForm.controls.yearPropStallone.setValue(isNull(denuncia.proprieallamontastallone.annotessera) ? '' : denuncia.proprieallamontastallone.annotessera);
          // this.basicForm.controls.cardNumberPropStallone.setValue(isNull(denuncia.proprieallamontastallone.nrtessera) ? '' : denuncia.proprieallamontastallone.nrtessera);
        } else {
          this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
            this.translateService.translate(this.translateItems, 'mes.proprietariostallonenodispo.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true)); // Proprietario stallone non disponibile
          this.errorRetrieveModA = true;
        }
      },
      error: (_err) => {
        this.errorRetrieveModA = true;
        this.visibleDialogCode = true;
        this.loading = false;
        this.errorRetrieveModA = true;
      }
    });
  }

  approveDenuncia() {
    this.confirmingDenuncia = true;
    this.moduloAbService.reCheckCode(this.currentCode, this.idAllevatore, this.idDenuncia).pipe(mergeMap(() => {
      return this.moduloAbService.approveModuloAByProprietarioStallone(this.idAllevatore, this.idDenuncia);
    })).subscribe({
      next: (result) => {
        this.confirmingDenuncia = false;
        if (result.status != 0) {
          if (result.message) {
            this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
              result.message, this.translateService.translate(this.translateItems, 'err.errore'), "error", true));
          }
          if (result.anomalie?.length) {
            result.anomalie.forEach((anomalia) => {
              this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
                anomalia.descrizione, this.translateService.translate(this.translateItems, 'err.errore'), "error", true));
            });
          }
        } else {
          this.router.navigate(['success'], { relativeTo: this.activatedRoute });
        }

      },
      error: (_err) => {
        this.confirmingDenuncia = false;
        this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
          this.translateService.translate(this.translateItems, 'mes.confermadenuncia.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true)); //Errore nella conferma della denuncia
      }
    });
  }

  refuseDenuncia() {

    this.showDialogRifiuto = true;
  }

  richiediNuovoCodice() {
    this.askingNuovoCodice = true;
    this.moduloAbService.resendOtpCode(this.idAllevatore, this.idDenuncia).subscribe({
      next: () => {
        this.askingNuovoCodice = false;
        this.showCodiceReinviato = true;
        setTimeout(() => {
          this.showCodiceReinviato = false;
        }, 10000);
      },
      error: (err) => {
        this.askingNuovoCodice = false;
        this.cannotReSendCodeReason = err.error;
      }
    })
  }

  setLang(lang) {
    this.langSubject$.next(lang);
  }

  rifiutaDenunciaDefinitivo() {
    if(!this.motivazioneRifiuto || this.motivazioneRifiuto.trim().length < 10) { 
      this.showReadsonNotValid = true;
      return;
    }
    this.confirmingDenuncia = true;
    this.moduloAbService.reCheckCode(this.currentCode, this.idAllevatore, this.idDenuncia).pipe(mergeMap(() => {
      return this.moduloAbService.refuseModuloAByProprietarioStallone(this.idAllevatore, this.idDenuncia, this.motivazioneRifiuto);
    })).subscribe({
      next: (result) => {
        this.confirmingDenuncia = false;
        if (result.status != 0) {
          if (result.message) {
            this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
              result.message, this.translateService.translate(this.translateItems, 'err.errore'), "error", true));
          }
          if (result.anomalie?.length) {
            result.anomalie.forEach((anomalia) => {
              this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
                anomalia.descrizione, this.translateService.translate(this.translateItems, 'err.errore'), "error", true));
            });
          }
        } else {
          this.router.navigate(['error'], { relativeTo: this.activatedRoute });
        }
      },
      error: (_err) => {
        this.confirmingDenuncia = false;
        this.messageService.add(createMessageToastPrimeNg(this.Constants.KeyPToastFastApprove,
          this.translateService.translate(this.translateItems, 'mes.rifiutodenuncia.errore'), this.translateService.translate(this.translateItems, 'err.errore'), "error", true)); //"Errore nel rifiuto della denuncia"
      }
    });
  }

  labelTipoSocio(tipoSocio: string) {
  
    let tipoSocioLabel = 'tiposocio.nonsocio.title';
    if (tipoSocio == 'SI') {
      tipoSocioLabel = 'tiposocio.socioallevatore.title'
    } else if (tipoSocio == 'SA') {
      tipoSocioLabel = 'tiposocio.socioaggregato.title';
    }
  
    return tipoSocioLabel;
  }
}
