import { Component } from '@angular/core';
import { TranslateService } from '../services/translate.service';
import { Observable, tap } from 'rxjs';
@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent {
  T$: Observable<any>;
  translateItems: any;

  constructor( private translateService: TranslateService){

  }
  ngOnInit(){
    this.T$ = this.translateService.translatedItems$(['moda','err.','mes.','cane.']).pipe(
      tap(items => {
        this.translateItems = items;
      })
    );

  }
}
