import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, takeUntil } from 'rxjs';
import { ModuloABService } from '../services/moduloab.service';


@Component({
  selector: 'authCallback',
  templateUrl: './auth-callback.component.html',
})
export class AuthCallbackComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private dataService: ModuloABService
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  
  ngOnInit() {
    this.oidcSecurityService.checkAuth()
    .pipe(
      takeUntil(this.destroy$)
    )    
    .subscribe({
      next: loginResponse => {
        // if (loginResponse.isAuthenticated) {
        //   this.dataService.getUser();
        // }
      },
      error: _error => {
      }
    });

  }

}
