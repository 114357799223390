import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataSearch } from '../modules/shared/models/data-search';
import { IEntityList } from '../modules/shared/models/entity';
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { AnomaliaModA, DocumentiFromScp, ModuloB, Nazione } from '../modules/shared/interfaces/scp';
import { DenunciaStalloneConfirmed, GetModuloAResponse, Listino, ModuloAEnci } from '../modules/shared/interfaces/enci';
import { adjustFieldsDenunciaFromEnci } from '../modules/shared/shared.functions';
import { OidcSecurityService } from 'angular-auth-oidc-client';

export function buildServiceUrl(moduloServiceUrl: string, routeUrl: string) {
  return `/${environment.restApiPrefix}/${moduloServiceUrl}/${routeUrl}`;
}

// export function buildFormData(entity: any) : FormData {
//   const result = new FormData();
//   Object.keys(entity).forEach(key => {
//     if (key === 'id' && !entity[key]) {
//       result.append(key, '0');
//     }
//     else {
//       if (entity[key] != undefined && entity[key] != null && entity[key]) {
//         if (entity[key] instanceof Date) {
//           result.append(key, (new Date(entity[key])).toUTCString());
//         } else if (entity[key] instanceof Object) {
//           for (let nestedKey in entity[key]) {
//             result.append(`${key}[${nestedKey}]`, entity[key][nestedKey]);
//           }
//         }
//         else {
//           result.append(key, entity[key]);
//         }
//       }
//     }
//   });
//   return result;
// }


export function buildFormData(object: any, form?: FormData, namespace?: string): FormData {
  const formData = form || new FormData();
  for (let property in object) {
    if (!object.hasOwnProperty(property) || object[property] == undefined || object[property] == null) {
      continue;
    }
    let formKey = namespace ? `${namespace}[${property}]` : property;
    if (object[property] instanceof Date) {
      formData.append(formKey, object[property].toISOString());
    } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
      buildFormData(object[property], formData, formKey);
    } else {
      formData.append(formKey, object[property]);
    }
  }
  return formData;
}

@Injectable({
  providedIn: 'root'
})
export class ModuloABService {

  private moduloABServiceUrl = 'moduloab';
  utente: any;

  private _errorFromServiceScp: ReplaySubject<any> = new ReplaySubject<any>(1);

  public getObsErrorFromServiceScp(): Observable<any> {
    return this._errorFromServiceScp.asObservable();
  }

  constructor(private oidcSecurityService: OidcSecurityService,
    private httpClient: HttpClient) { }

  generateUrlUpload(module: string, url: string, id: string, mode: number): string {
    return `/${environment.restApiPrefix}/${this.moduloABServiceUrl}/${module}/${url}/${id}/${mode}`;
  }

  getLanguages(): Observable<any[]> {
    let url = buildServiceUrl(this.moduloABServiceUrl, 'languages');
    return this.httpClient.get<any[]>(url);
  }

  getLabels(startWith: string[], lang?: string): Observable<any[]> {
    let url = buildServiceUrl(this.moduloABServiceUrl, 'labels');
    const strStartWith = startWith.join('|');

    let httpParams = new HttpParams();
    httpParams = httpParams.set("startWith", strStartWith);
    
    if(lang) {
      httpParams = httpParams.set("lang", lang);
    }
    return this.httpClient.get<any[]>(url, { params: httpParams });
  }
  
  getUtente(): Observable<any> {
    const vUrl = `${buildServiceUrl(this.moduloABServiceUrl, 'user')}`;
    return this.httpClient.request<any>("GET", vUrl).pipe(
      tap(utente => this.utente = utente)
    );
  }

  upsertUtente(utente: any): Observable<any> {
    const vUrl = `${buildServiceUrl(this.moduloABServiceUrl, 'user')}`;
    return this.httpClient.put(vUrl, utente).pipe(
      tap(utente => this.utente = utente)
      // catchError(error => {
      //   this.utente = undefined;
      //   return this.utente;
      // })
    );
  }

  searchEntities<T>(url: string, dataSearch?: DataSearch): Observable<IEntityList<T>> {
    let vUrl = buildServiceUrl(this.moduloABServiceUrl, url);
    if (dataSearch) {
      let queryStringParam = dataSearch.toQueryString();
      vUrl = `${vUrl}?${queryStringParam}`;
    }
    return this.httpClient.request<IEntityList<T>>("GET", vUrl);
  }

  getEntity<T>(url: string): Observable<T> {
    let vUrl = buildServiceUrl(this.moduloABServiceUrl, url);
    return this.httpClient.get<T>(vUrl);
  }

  insertEntity<T>(url: string, entity: T, isFormData = false): Observable<T> {
    let vUrl = buildServiceUrl(this.moduloABServiceUrl, url);
    if (isFormData) {
      const formData = buildFormData(entity);
      return this.httpClient.request<T>("POST", vUrl, { body: formData });
    }
    else {
      return this.httpClient.request<T>("POST", vUrl, { body: entity });
    }
  }

  updateEntity<T>(url: string, entity: T, isFormData = false): Observable<T> {
    let vUrl = `${buildServiceUrl(this.moduloABServiceUrl, url)}/${entity['id']}`;
    if (isFormData) {
      const formData = buildFormData(entity);
      return this.httpClient.request<T>("PUT", vUrl, { body: formData });
    }
    else {
      return this.httpClient.request<T>("PUT", vUrl, { body: entity });
    }
  }

  deleteEntity(url: string, id: string): Observable<any> {
    let vUrl = `${buildServiceUrl(this.moduloABServiceUrl, url)}/${id}`;
    return this.httpClient.delete<any>(vUrl)
  }

  sendOtpProprietarioStallone(cellulare: string, idAllevatore: string, idDenuncia: string,
    dataNascita: string, nomeStallone: string, nomeFattrice: string) : Observable<any> {
    let formData = new FormData();
    formData.append("cellulare", cellulare);
    formData.append("idAllevatore", idAllevatore);
    formData.append("idDenuncia", idDenuncia);
    formData.append("dataNascita", dataNascita);
    formData.append("nomeStallone", nomeStallone);
    formData.append("nomeFattrice", nomeFattrice);

    return this.httpClient.post<any>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/sendOtpRequestProprietarioStallone`, formData);
  }

  sendMailRequestProprietarioStallone(email: string, idAllevatore: string, idDenuncia: string,
    dataNascita: string, nomeStallone: string, nomeFattrice: string, idPropFattrice: string): Observable<any> {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("idAllevatore", idAllevatore);
    formData.append("idDenuncia", idDenuncia);
    formData.append("dataNascita", dataNascita);
    formData.append("nomeStallone", nomeStallone);
    formData.append("nomeFattrice", nomeFattrice);
    formData.append("idPropFattrice", idPropFattrice);

    return this.httpClient.post<any>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/sendMailRequestProprietarioStallone`, formData);
  }

  approveModuloAByProprietarioStallone(idAllevatore: string, idDenuncia: string): Observable<DenunciaStalloneConfirmed> {
    let formData = new FormData();
    formData.append("idAllevatore", idAllevatore);
    formData.append("idDenuncia", idDenuncia);

    return this.httpClient.post<DenunciaStalloneConfirmed>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/approveDenunciaModuloAPropStallone`, formData);
  }

  refuseModuloAByProprietarioStallone(idAllevatore: string, idDenuncia: string, motivazioneRifiuto: string): Observable<DenunciaStalloneConfirmed> {
    let formData = new FormData();
    formData.append("idAllevatore", idAllevatore);
    formData.append("idDenuncia", idDenuncia);
    formData.append("motivazioneRifiuto", motivazioneRifiuto);

    return this.httpClient.post<DenunciaStalloneConfirmed>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/refuseDenunciaModuloAPropStallone`, formData);
  }

  //solo in approvazione singola, senza auth
  //controlla il codice inserito dall'utente se e' valido
  checkCode(code: string, idAllevatore: string, idDenuncia: string): Observable<any> {
    let formData = new FormData();
    formData.append("code", code);
    formData.append("idAllevatore", idAllevatore);
    formData.append("idDenuncia", idDenuncia);

    return this.httpClient.post<any>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/validateOtp`, formData);
  }

  //solo in approvazione singola, senza auth
  //ricontrolla il codice inserito dall'utente se e' ancora valido
  reCheckCode(code: string, idAllevatore: string, idDenuncia: string): Observable<any> {
    let formData = new FormData();

    formData.append("code", code);
    formData.append("idAllevatore", idAllevatore);
    formData.append("idDenuncia", idDenuncia);

    return this.httpClient.post<any>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/isOtpStillValid`, formData);
  }

  //solo in approvazione singola, senza auth
  resendOtpCode(idAllevatore: string, idDenuncia: string) {
    let formData = new FormData();
    formData.append("idAllevatore", idAllevatore);
    formData.append("idDenuncia", idDenuncia);

    return this.httpClient.post<any>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/resendOtpRequestProprietarioStallone`, formData);
  }

  //controlla se c'e' uno ancora attivo
  checkNeedOtpPermanent(idAllevatore: string): Observable<boolean> {
    let formData = new FormData();
    formData.append("idAllevatore", idAllevatore);

    return this.httpClient.post<any>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/checkNeedOtpPermanent`, formData)
      .pipe(map((res) => { return res?.message != 'ALLOWED' }));
  }

  //controlla se e' giusto
  checkOtpPermanent(idAllevatore: string, code: string): Observable<any> {
    let formData = new FormData();
    formData.append("idAllevatore", idAllevatore);
    formData.append("code", code);

    return this.httpClient.post<any>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/checkOtpPermanent`, formData);
  }

  //chiede un nuovo codice
  askOtpPermament(idAllevatore: string, cellulare: string, dataNascita: string, nomeStallone: string, nomeFattrice: string): Observable<any> {
    let formData = new FormData();
    formData.append("idAllevatore", idAllevatore);
    formData.append("cellulare", cellulare);
    formData.append("dataNascita", dataNascita);
    formData.append("nomeStallone", nomeStallone);
    formData.append("nomeFattrice", nomeFattrice);
    return this.httpClient.post<any>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/askOtpPermament`, formData)
  }

  downloadFileModuloa(id: string, mode: number): Observable<any> {
    let url = `/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/downloadDoc/${id}/${mode}`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), responseType: 'blob' as 'json'
    };

    return this.httpClient.get<any>(url, options);
  }

  downloadFileModulob(id: string, mode: number): Observable<any> {
    let url = `/${environment.restApiPrefix}/${this.moduloABServiceUrl}/modulob/downloadDoc/${id}/${mode}`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), responseType: 'blob' as 'json'
    };

    return this.httpClient.get<any>(url, options);
  }

  registraAnomalieModuloA(id: string, tipoAnomalie: number, anomalie: AnomaliaModA[]): Observable<any> {

    let url = `/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/registraAnomalie/${id}/${tipoAnomalie}`;
    return this.httpClient.post<any>(url, anomalie);
  }

  getAnomalie(id: number): Observable<AnomaliaModA[]> {
    let url = `/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/getAnomalie/${id.toString()}`;
    return this.httpClient.get<AnomaliaModA[]>(url);
  }

  getNazioni(): Observable<Nazione[]> {
    return this.httpClient.get<Nazione[]>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/nazioni`).pipe(map((res) => {
      res.forEach((nazione) => {
        nazione.isDisabled = nazione.siglaIso3166Alpha2 == 'IT';
      });
      return res;
    }));
  }

  sendDocumentiToAdiuto(id: string): Observable<DocumentiFromScp[]> {
    return this.httpClient.post<DocumentiFromScp[]>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/uploadToAdiuto/${id}`, null);
  }

  sendDocumentiToAdiutoModuloB(id: string, idDelegazione: string): Observable<DocumentiFromScp[]> {
    return this.httpClient.post<DocumentiFromScp[]>(`/${environment.restApiPrefix}/${this.moduloABServiceUrl}/modulob/uploadToAdiuto/${id}/${idDelegazione}`, null);
  }

  insertUpdateModuloB(entity: ModuloB): Observable<ModuloB> {
    let vUrl = `${buildServiceUrl(this.moduloABServiceUrl, 'modulob')}`;
    const formData = buildFormData(entity);
    return this.httpClient.post<ModuloB>(vUrl, formData);
  }

  // getSidebarVisibile(): Observable<boolean> {
  //   return this._sidebarVisible.asObservable();
  // }

  // setSidebarVisibile(val: boolean) {
  //   return this._sidebarVisible.next(val);
  // }

  getLinkPagamento(idDenuncia: string, idAllevatore: string, listini: Listino[]): Observable<string> {
    let url = `/${environment.restApiPrefix}/${this.moduloABServiceUrl}/pagamento/${idAllevatore}/${idDenuncia}`;

    let formData = new FormData();
    formData.append("listini", JSON.stringify(listini));
    return this.httpClient.post<string>(url, formData);
  }

  getErrorePagamento(id: string): Observable<any> {
    let url = `/${environment.restApiPrefix}/${this.moduloABServiceUrl}/pagamento/errore/${id}`;
    return this.httpClient.get<any>(url);
  }


  getAllDenunceDaConfermarePropStallone(idAllevatore: string): Observable<ModuloAEnci[]> {

    let formData = new FormData();
    formData.append("idAllevatore", idAllevatore);

    return this.httpClient.post<GetModuloAResponse>(
      `/${environment.restApiPrefix}/${this.moduloABServiceUrl}/moduloa/getAllDenunceDaConfermare`,
      formData
    ).pipe(map((response) => {
      if (response.status != 0) {
        if (response.status == 1 && response.message == 'Nessun record trovato') {
          let denunce: ModuloAEnci[] = [];
          return denunce;
        }
        if (response.anomalie?.length) {
          response.anomalie.forEach((anomalia) => {
            this._errorFromServiceScp.next(anomalia.descrizione);
          });
        } else {
          this._errorFromServiceScp.next(response.message);
        }
        let denunce: ModuloAEnci[] = [];
        return denunce;
      } else {
        return response.result;
      }
    }));
  }


  getDenunciaDaConfermarePropStallone(idAllevatore: string, idDenuncia: string): Observable<ModuloAEnci> {
    return this.getAllDenunceDaConfermarePropStallone(idAllevatore).pipe(map((denunce) => {
      let denuncia = denunce.find(d => d.iddenuncia == idDenuncia);
      if(!denuncia) return undefined;
      
      return adjustFieldsDenunciaFromEnci(denuncia);
    }));
  }

  searchImpersonification(match: string): Observable<any[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("match", match);
    return this.oidcSecurityService.getConfiguration().pipe(mergeMap((config) => {
      let url = config.authority + '/api/userManager/appuser/searchApplicationUsers';
      return this.httpClient.get<any[]>(url, { params: httpParams });
    }));
  }
}
