import { Component, OnDestroy, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { EnciApiService } from '../../../../services/enciapi.service';
import { BaseComponent } from '../../components/base/base.component';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { labelTipoSocio } from '../../shared.functions';
import { Router } from '@angular/router';
import { isNil } from 'lodash-es';
import { Observable, of, Subject } from 'rxjs';
import { ModuloABService } from '../../../../services/moduloab.service';
import { TranslateService } from 'src/app/services/translate.service';
import { configId } from '../../../../authorization/auth-config.module';
import { Allevatore } from '../../interfaces/enci';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  openSidebar = false;

  nomeCognomeAllevatore: string;
  labelTipoSocio: string;
  acronymTipoSocio: string = "N/A";

  showBlockedPopup: boolean;
  T$: Observable<any>;
  translateItems: any;

  languages$: Observable<any[]>;
  languages: any[];
  languageCurrent: any = {};

  userToImpersonate: any;
  searchResultsUser: any[];
  canImpersonate: boolean;
  private searchTermsuser$ = new Subject<string>();

  isImpersonating: boolean;

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private enciAppService: EnciApiService,
    private moduloABService: ModuloABService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.languages$ = this.moduloABService.getLanguages().pipe(
      tap(languages => {
        this.languages = languages;
        if (this.moduloABService.utente) {
          this.languageCurrent = languages.find(language => language.id == this.moduloABService.utente.langId);
        }
      })
    );

    this.T$ = this.translateService.translatedItems$(['header.', 'tiposocio.']).pipe(
      tap(items => {
        this.translateItems = items;
      })
    );


    this.oidcSecurityService.userData$.pipe(filter((u) => {
      return !isNil(u.userData);
    }), switchMap((user) => {
      this.canImpersonate = this.isOperatoreImpersonate(user.userData);
      if (user.userData.fiscal_code) {
        this.isImpersonating = user.userData?.impersonate;
        return this.enciAppService.getAllevatore(user.userData.fiscal_code);
      } else {
        return of(undefined);
      }
    }), takeUntil(this.destroy$))
      .subscribe({
        next: (allevatore: Allevatore | undefined) => {
          if (!allevatore) {
            return;
          }
          this.nomeCognomeAllevatore = allevatore.anagrafica.nome;
          this.labelTipoSocio = this.translateService.translate(this.translateItems, labelTipoSocio(allevatore));
          this.acronymTipoSocio = allevatore.anagrafica.tiposocio ?? 'N/A';

          this.showBlockedPopup = allevatore.anagrafica.isPagatoTessera != 'S';
        },
        error: (error) => {
          console.error(error);
        }
      });

    this.searchTermsuser$.pipe(switchMap((term: string) => {
      return this.moduloABService.searchImpersonification(term);
    }), takeUntil(this.destroy$)).subscribe(data => {
      this.searchResultsUser = data;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  logout() {
    this.oidcSecurityService.logoff().subscribe();
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  setLanguage(langId: number) {
    this.languageCurrent = this.languages.find(language => language.id == langId);

    this.moduloABService.utente.langId = this.languageCurrent.id;
    this.moduloABService.upsertUtente(this.moduloABService.utente).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      complete: () => {
        window.location.reload();
      }
    });
  }

  impersonate(event: any) {
    let userToImpersonate = event.value;
    let data = {};
    data["acr_values"] = "Impersonate:" + userToImpersonate.id;
    this.oidcSecurityService.authorize(configId, { customParams: data });
  }

  removeImpersonation() {
    this.oidcSecurityService.authorize(configId, { customParams: {} });
  }

  searchUsers(event: any) {
    let query = event.query;
    this.searchTermsuser$.next(query);
  }

  isOperatoreImpersonate(claims: any): boolean {
    return claims.role && (claims.role.indexOf("OperatoreImpersonamento") > -1);
  }
}
