<ng-container *ngIf="T$ | async as T">
<div class="custom-shadow-1 border-round p-3" [class.background-stallone]="sex == 'M'"
  [class.background-fattrice]="sex == 'F'">
  <div class="grid -mb-2">
    <div class="col-12 xl:col-6">
      <div class="flex align-items-center gap-2">
        <img src="assets/icons/zampa.svg" />
        <div class="text-blue-600 font-semibold break-word">{{ cane.nome }}</div>
      </div>
    </div>
    <div class="col-12 xl:col-6">
      <div class="flex align-items-center gap-2">
        <span class="text-sm">{{ T | translateItem: 'modb.datanascita'}}</span>
        <span class="font-semibold break-word">{{ cane.datanascita }}</span>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col">
      <div class="grid -mb-2">
        <div class="col-12 xl:col-6">
          <div class="text-sm">{{ T | translateItem: 'cane.niscrizione'}}</div>
          <div class="font-semibold break-word">{{ cane.codice }}</div>
        </div>
        <div class="col-12 xl:col-6">
          <div class="text-sm">{{ T | translateItem: 'cane.razza'}}</div>
          <div class="font-semibold break-word">{{ cane.razza }}</div>
        </div>
        <div class="col-12 xl:col-6">
          <div class="text-sm">{{ T | translateItem: 'cane.mantello'}}</div>
          <div class="font-semibold break-word">{{ cane.mantello }}</div>
        </div>
        <div class="col-12 xl:col-6" *ngIf="cane.idmarca">
          <div class="text-sm">{{ T | translateItem: 'cane.tatuaggio'}}</div>
          <div class="font-semibold break-word">{{ cane.idmarca }}</div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="grid -mb-2">
        <div class="col-12 xl:col-6">
          <div class="text-sm">{{ T | translateItem: 'cane.microchip'}}</div>
          <div class="font-semibold break-word">{{ cane.chip }}</div>
        </div>
        <ng-container *ngIf="mostraProprietario">
          <div class="col-12 xl:col-6">
            <div class="text-sm">{{ T | translateItem: 'cane.proprietario'}}</div>
            <div class="font-semibold break-word">{{cane.nomeproprietario}}</div>
          </div>
          <div class="col-12 xl:col-6">
            <div class="text-sm">{{ T | translateItem: 'cane.email'}}</div>
            <div class="font-semibold break-word">{{cane.emailproprietario}}</div>
          </div>
          <div class="col-12 xl:col-6">
            <div class="text-sm">{{ T | translateItem: 'cane.telefono'}}</div>
            <div class="font-semibold break-word">{{cane.telefonobisproprietario ? cane.telefonobisproprietario : cane.telefonoproprietario}}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
</ng-container>