<ng-container *ngIf="T$ | async as T">
<div class="bg-blue-600 flex flex-column lg:flex-row lg:justify-content-between py-2 px-3">
  <div class="flex gap-3 align-items-center justify-content-center lg:justify-content-start">
    <img src="assets/icons/logo_footer.svg" class="h-2rem" />
    <div>
      <div class="text-base max-w-17rem text-white font-semibold line-height-1">
        {{ T | translateItem: 'footer.enci' }}
        <!-- Ente Nazionale della Cinofilia Italiana -->
      </div>
    </div>
  </div>

  <div class="mt-3 flex justify-content-between align-items-center lg:gap-4 lg:mt-0">
    <a class="text-white underline text-sm cursor-pointer" href="https://www.enci.it/privacy" target="_blank"
      rel="noopener noreferrer">
      {{ T | translateItem: 'footer.privacy' }}
      <!-- Privacy -->
    </a>
    <a class="text-white underline text-sm cursor-pointer line-height-1" href="https://www.enci.it/copyright"
      target="_blank" rel="noopener noreferrer">
      {{ T | translateItem: 'footer.copyright' }}
      <!-- Copyright -->
    </a>
    <a class="text-white underline text-sm cursor-pointer line-height-1"
      href="https://www.enci.it/tariffe-e-diritti-enci" target="_blank" rel="noopener noreferrer">
      {{ T | translateItem: 'footer.tariffe' }}
      <!-- Tariffe e diritti ENCI -->
    </a>
    <a class="text-white underline text-sm cursor-pointer line-height-1" href="https://www.enci.it/credits"
      target="_blank" rel="noopener noreferrer">
      {{ T | translateItem: 'footer.credits' }}
      <!-- Credits -->
    </a>
  </div>
</div>
</ng-container>