import { Component, OnInit } from '@angular/core';
import { Observable, ReplaySubject, mergeMap, tap } from 'rxjs';
import { TranslateService } from 'src/app/services/translate.service';
import { getLanguageFromBrowser } from '../../modules/shared/shared.functions';
@Component({
	selector: 'fast-approve-refused',
	templateUrl: 'fast-approve-refused.component.html'
})

export class FastApproveRefusedComponent implements OnInit {
	T$: Observable<any>;
	translateItems: any;
	langSubject$: ReplaySubject<string> = new ReplaySubject<string>(1);

	constructor(private translateService: TranslateService) { }

	ngOnInit() {
		this.T$ = this.langSubject$.asObservable().pipe(mergeMap((lang) => {
			return this.translateService.translatedItems$([ 'err.', 'mes.'], lang)
		}),
			tap(items => {
				this.translateItems = items;
			})
		);
		let lang = getLanguageFromBrowser();
    	this.langSubject$.next(lang);
	}
}