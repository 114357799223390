import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../shared/components/base/base.component';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '../../services/translate.service';
import { Observable, map, tap } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  T$: Observable<any> = this.translateService.translatedItems$(['menu']).pipe(
    tap(items => {
      this.translateItems = items;
    })
  );
  translateItems: any;
  navigationItems$: Observable<MenuItem[]>;
  hasFiscalCode$: Observable<boolean>;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private oidcSecurityService: OidcSecurityService
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.navigationItems$ = this.T$.pipe(
      map(_ => {
        return [
          {
            icon: 'plus',
            label: this.translateService.translate(this.translateItems, 'menu.inseriscimodelloa'), //'Inserisci un nuovo modello A'
            title: this.translateService.translate(this.translateItems, 'menu.nuovadenuncia'), //'Inserisci una nuova denuncia.',
            routerLink: ['/genetic', 'create'],
            styleClass: 'app-bg-home-card-plus'
          },
          {
            icon: 'folder_open',
            label: this.translateService.translate(this.translateItems, 'menu.miedenunce'), //'Le mie denunce',
            title: this.translateService.translate(this.translateItems, 'menu.consultadenunce'), //'Consulta le tue denunce.',
            routerLink: ['/genetic', 'search'],
            queryParams: { 'id': 'APERTE' }
          },
          {
            icon: 'check',
            label: this.translateService.translate(this.translateItems, 'menu.autorizzazionistallone'), //' Autorizzazioni stallone',
            title: this.translateService.translate(this.translateItems, 'menu.denuncestalloni'),
            routerLink: ['/genetic', 'search'],
            queryParams: { 'id': 'IN_ATTESA_CONF' }
          },
          // {
          //   icon: 'file_arrow_right',
          //   label: 'Passaggi di proprietà',
          //   title: 'Gestisci i passaggi di proprietà dei tuoi cani.',
          //   routerLink: ['/handover']
          // },
          {
            icon: 'help',
            label: this.translateService.translate(this.translateItems, 'menu.guidautente'), // 'Guida per l'utente',
            title: this.translateService.translate(this.translateItems, 'menu.consultaguidautente'), // 'Consulta la guida per l'utente del portale.',
            url: environment.urlHelpGuide,
            styleClass: 'block lg:hidden'
          }
        ];
      })
    );

    this.hasFiscalCode$ = this.oidcSecurityService.userData$.pipe(map((u) => {
      return !isNil(u.userData?.fiscal_code);
    }));

  }

  navigate(routerLink: string[], queryParams: any, url: string) {
    if (url) {
      window.open(url, '_blank', 'noopener noreferrer');
    } else {
      this.router.navigate(routerLink, { queryParams: queryParams });
    }
  }
}
