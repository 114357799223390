import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layouts/header/header.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { AvatarModule } from 'primeng/avatar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { RouterModule, RouterOutlet } from '@angular/router';
import { FooterComponent } from './layouts/footer/footer.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AccordionModule } from 'primeng/accordion';
import { FileDropzoneComponent } from './components/file-dropzone/file-dropzone.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SidebarModule } from 'primeng/sidebar';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { CustomTextareaComponent } from './components/custom-textarea/custom-textarea.component';
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker.component';
import { CalendarModule } from 'primeng/calendar';
import { BaseComponent } from './components/base/base.component';
import { ToastModule } from 'primeng/toast'
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputNumberModule } from 'primeng/inputnumber';
import { AcronymPipe, GeneticStatusPipe, GetOnlyDateFromStringsPipe, TranslateItemPipe } from './shared.pipes';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ModuleStateDirective } from 'src/app/directives/module-state.directive';
import { InputTextModule } from 'primeng/inputtext';
import { SchedaCaneComponent } from './components/scheda-cane/scheda-cane.component';
import { FileUploadModule } from 'primeng/fileupload';
import { FileSaverModule } from 'ngx-filesaver';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OperationStatusComponent } from './components/operation-status/operation-status.component';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    CustomIconComponent,
    FileDropzoneComponent,
    CustomInputComponent,
    CustomTextareaComponent,
    CustomDatepickerComponent,
    BaseComponent,
    AcronymPipe,
    GeneticStatusPipe,
    GetOnlyDateFromStringsPipe,
    ModuleStateDirective,
    SchedaCaneComponent,
    OperationStatusComponent,
    TranslateItemPipe
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AvatarModule,
    OverlayPanelModule,
    ButtonModule,
    RouterOutlet,
    RouterModule,
    PanelMenuModule,
    AccordionModule,
    NgxDropzoneModule,
    SidebarModule,
    CheckboxModule,
    CalendarModule,
    ToastModule,
    ProgressSpinnerModule,
    InputNumberModule,
    TooltipModule,
    ConfirmDialogModule,
    DialogModule,
    KeyFilterModule,
    InputTextModule,
    FileUploadModule,
    FileSaverModule,
    TableModule,
    InputTextareaModule,
    AutoCompleteModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FileDropzoneComponent,
    CustomInputComponent,
    CustomTextareaComponent,
    CustomDatepickerComponent,
    BaseComponent,
    ToastModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    InputNumberModule,
    AcronymPipe,
    GeneticStatusPipe,
    TooltipModule,
    ConfirmDialogModule,
    DialogModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    GetOnlyDateFromStringsPipe,
    KeyFilterModule,
    ModuleStateDirective,
    InputTextModule,
    SchedaCaneComponent,
    FileUploadModule,
    FileSaverModule,
    TableModule,
    OperationStatusComponent,
    InputTextareaModule,
    TranslateItemPipe
  ],
  providers: [
    ConfirmationService,
    MessageService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true
    }
  ]
})
export class SharedModule {
}
