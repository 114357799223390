<ng-container *ngIf="T$ | async as T">
  <div class="hidden lg:flex app-header justify-content-between align-items-center custom-shadow-2 gap-2">
    <div class="flex cursor-pointer" (click)="goToHome()">
      <img src="assets/icons/logo.svg" class="w-5rem" />
      <h1 class="title ml-5">
        {{T| translateItem: 'header.denunciamontaecucciolata'}}
      </h1>
    </div>
    <div class="flex align-items-center gap-3 font-semibold">
      <div class="flex align-items-center gap-3" *ngIf="canImpersonate">
        <div>
          {{T | translateItem: 'header.impersonate'}}
        </div>
        <p-autoComplete [suggestions]="searchResultsUser" (completeMethod)="searchUsers($event)"
          (onSelect)="impersonate($event)" [(ngModel)]="userToImpersonate" appendTo="body" [minLength]="3"
          [field]="'fiscalCode'" [forceSelection]="true" [showEmptyMessage]="true" 
          [emptyMessage]="T | translateItem: 'header.nouserfound'"
          [placeholder]="T | translateItem: 'header.typethreeletter'">
          <ng-template let-user pTemplate="item">
            <div>
              <div>{{user.fiscalCode}}</div>
              <div>{{user.email}}</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
      <div *ngIf="isImpersonating">
        <button pButton type="button" (click)="removeImpersonation()"
          label="{{T | translateItem: 'header.stopimpersonate'}}" class="p-button-danger"></button>
      </div>
      <ng-container *ngIf="nomeCognomeAllevatore">
        <div class="flex flex-column gap-2">
          <div class="flex align-items-center gap-2 cursor-pointer" (click)="op.toggle($event, targetMenu)">
            <div class="line-height-1">{{nomeCognomeAllevatore}}</div>
            <i class="pi pi-angle-down" #targetMenu></i>
          </div>
          <div class="flex align-items-center justify-content-end gap-2 cursor-pointer"
            (click)="opLanguage.toggle($event, targetLanguage)">
            <img *ngIf="languageCurrent.lang" src="assets/icons/Flag/{{languageCurrent.lang}}.png" width="24"
              height="24">
            <div class="text-sm align-items-center line-height-1">{{languageCurrent.des}}</div>
            <i class="text-sm pi pi-angle-down" #targetLanguage></i>
          </div>
        </div>
        <div class="avatar flex justify-content-center align-items-center line-height-1 bg-light-blue custom-shadow-1"
          [pTooltip]="labelTipoSocio" tooltipPosition="left">
          <i class="pi pi-user"></i>
        </div>
      </ng-container>
      <ng-container *ngIf="!nomeCognomeAllevatore">
        <div>
          <button pButton type="button" (click)="logout()" label="Logout"></button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="lg:hidden flex justify-content-between align-items-center px-3 py-2 custom-shadow-2">
    <div class="flex" (click)="openSidebar = true">
      <img src="assets/icons/menu.svg" />
    </div>
    <p-sidebar [(visible)]="openSidebar" position="left" styleClass="w-18rem">
      <ng-template pTemplate="content">
        <app-sidebar [isInSidebar]="true" (hideSidebar)="openSidebar = !$event"></app-sidebar>
      </ng-template>
    </p-sidebar>
    <div class="flex flex-column align-items-center cursor-pointer flex-grow-1 mx-0" (click)="goToHome()">
      <img src="assets/icons/logo.svg" class="w-3rem" />
      <span class="text-blue-600 text-sm">
        {{ T | translateItem: 'header.denunciamontaecucciolata'}}
      </span>
    </div>
  </div>

  <p-overlayPanel #op>
    <button pButton type="button" (click)="logout()" label="Logout"></button>
  </p-overlayPanel>
  <p-overlayPanel #opLanguage>
    <div class="flex flex-column gap-2" *ngIf="languages$ | async as languages">
      <p-button *ngFor="let language of languages" [text]="true"
        (click)="setLanguage(language.id); opLanguage.toggle($event);">
        <img src="assets/icons/Flag/{{language.lang}}.png" height="32" width="32">&nbsp;{{language.des}}
      </p-button>
    </div>
  </p-overlayPanel>

  <p-dialog header="" [(visible)]="showBlockedPopup" [closeOnEscape]="false" [closable]="false" [modal]="true"
    styleClass="app-modal-window-sm" [draggable]="false" [resizable]="false">

    <ng-template pTemplate="content">
      <div class="flex flex-column gap-3 align-items-center">
        <div>
          <i class="pi pi-exclamation-triangle text-4xl app-error"></i>
        </div>

        <div class="text-lg font-semibold app-error">
          {{T | translateItem: 'header.attenzione'}}
        </div>

        <div>
          {{T | translateItem: 'header.rinnovotessera'}}
        </div>

        <div class="mt-4">
          <button pButton type="button" (click)="logout()" label="{{T | translateItem: 'header.logout.btn'}}"></button>
        </div>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>