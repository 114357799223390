import isNil from "lodash-es/isNil";
import isEmpty from "lodash-es/isEmpty";
import { Cucciolo } from "../interfaces/scp";

export const dateRegex = /^(0[1-9]|[12][0-9]|3[01]|)(\/|-)(0[1-9]|1[1,2])(\/|-)(19|20)\d{2}|(19|20)\d{2}(\/|-)(0[1-9]|1[1,2])(\/|-)(0[1-9]|[12][0-9]|3[01]|)/;

export function valueIsDate(val: string): boolean {
  return (dateRegex.test(val) && !(isNaN(new Date(val).getTime()) || isNaN(parseInt(val))));
}

export function cuccioloHasValue(cucciolo: Cucciolo): boolean {

  return !isNil(cucciolo) && (!isEmpty(cucciolo.nome) || !isNil(cucciolo.microchip));
  }