import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, forwardRef, LOCALE_ID, NgModule } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthConfigModule } from './authorization/auth-config.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HomeComponent } from './modules/home/home.component';
import { SharedModule } from './modules/shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { HomeModule } from './modules/home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResultResponseInterceptor } from './interceptors/result-response.interceptor';
import { CustomInputComponent } from './modules/shared/components/custom-input/custom-input.component';
import { UnauthorizedComponent } from './authorization/unauthorized.component';
import { FastApproveComponent } from './components/fast-approve/fast-approve.component';
import localeIt from '@angular/common/locales/it';
import { DropdownModule } from 'primeng/dropdown';
import { HomeCardComponent } from './modules/home/home-card.component';
import { FastApproveSuccessComponent } from './components/fast-approve-success/fast-approve-success.component';
import { FastApproveRefusedComponent } from './components/fast-approve-refused/fast-approve-refused.component';
import { RadioButtonModule } from 'primeng/radiobutton';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeCardComponent,
    UnauthorizedComponent,
    FastApproveRefusedComponent,
    FastApproveSuccessComponent,
    FastApproveComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthConfigModule,
    AppRoutingModule,
    SharedModule,
    ButtonModule,
    RadioButtonModule,
    HomeModule,
    DropdownModule,
    SharedModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: HTTP_INTERCEPTORS, useClass: ResultResponseInterceptor, multi: true },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
