export const KeyPToastApp = "key_toast_app";
export const KeyPToastModuloA = "key_toast_module_a";
export const KeyPToastModuloB = "key_toast_module_b";
export const KeyPToastHome = "key_toast_home";
export const KeyConfirmDialogHome = "key_confirm_dialog_home";
export const KeyConfirmDialogLitterB = "key_confirm_dialog_litter_b";
export const KeyPToastFastApprove = "key_fast_approve";
export const KeyFastApproveConfirmDialog = "key_confirm_dialog_fast_approve";
export const KeyApproveConfirmDialog = "key_approve_confirm_dialog";

export const elencoCodeAnomalieDocIdoneita : string[] = ['FATTSEVENOLD', 'CERTIDORIPRO', 'FATTSESTA'];
export const codAnomaliaDocFistola: string = 'DMNCERTFISTOLA';
export const codAnomaliaFistolaFattrice : string = 'DMNFATTFISTOLA';
export const codAnomaliaFistolaStallone : string = 'DMNSTALFISTOLA';

export const codRazzaConFistola: string = '146';

export const EmailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"; //il validatore standard accetta le mail locali tipo mail@mail
export const TelephonePattern = "^(\\+[0-9]{2})?([0-9]{3,4}[ \\-]?){2}([0-9]{3,4})$"