import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Constants } from '../../shared.export-constants';

@Component({
	selector: 'base',
	template: ''
})

export class BaseComponent implements OnInit, OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();
	Constants = Constants;
	constructor() {
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}