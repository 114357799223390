import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../shared/components/base/base.component';

@Component({
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss']
})
export class HomeCardComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  title: string = '';

  @Input()
  message: string = '';

  @Input()
  styleClass: string = null;

  @Output()
  clieckEvent: EventEmitter<void> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
